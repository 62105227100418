"use strict";
import Barba from "barba.js";
import Env from "../base/env.js";
import Channels from "../base/channels";
import Splide from "@splidejs/splide";
import Client from "shopify-buy";
import { renderPrice } from "../components/Helpers";
import gsap from "gsap";

let page;

class Page {
  constructor(view = "") {
    this.view = view;
    this.DOM = {};
    this.DOM.view = this.view;
    this.DOM.product = this.view.querySelector(".jsProduct");
    this.DOM.addToCart = this.DOM.view.querySelector(".jsAddToCart");
    this.DOM.splide = this.DOM.view.querySelector(".splide");
    this.DOM.relatedItems = this.DOM.view.querySelectorAll(".jsRelated");
    this.classView = "currentView--product";
    // Shopify
    this.shopifyClient = Client.buildClient({
      domain: Env.shopifyDomain,
      storefrontAccessToken: Env.storefrontAccess,
    });
    this.getVariantID = false;
    this.timeoutCallbackAdded = false;
    // Launch Fn
    this.funcLaunch = this.intro.bind(this);
    if (Env.framework.UI.launcher.LAUNCHED == false) this.channels();
  }

  channels() {
    Channels.on("launcher::exit", this.funcLaunch);
  }

  destroy() {
    Channels.removeListener("launcher::exit", this.funcLaunch);
  }

  finalize() {
    setTimeout(() => {
      if (Env.isVisiting === true) {
        this.intro();
      }
      this.popinMode = Env.$html.classList.contains("popin--isOpened");
      if (this.popinMode) {
        this.DOM.master = document.getElementById("jsPopinView");
      } else {
        this.DOM.master = this.DOM.view;
      }
      this.loadShopifyDatas();
      setTimeout(() => {
        wp.hooks.doAction("do.shopRender");
      }, 500);
    }, 300);
  }

  loadShopifyDatas() {
    // Fetch a single product by Handle
    const handle = this.DOM.master
      .querySelector(".fxProductContainer")
      .getAttribute("data-handle");
    console.log(handle);
    this.shopifyClient.product.fetchByHandle(handle).then((product) => {
      console.log(product);
      this.getVariantID = product.variants[0].id;
      const imgProduct = new Image();
      imgProduct.classList.add("tw-w-full");
      imgProduct.classList.add("tw-h-full");
      imgProduct.classList.add("tw-object-cover");
      imgProduct.classList.add("tw-relative");
      imgProduct.src = product.images[0].src;
      if (this.popinMode) {
        if (Env.mobileCSS) {
          this.DOM.master
            .querySelector(".imgReceptorMobile")
            .appendChild(imgProduct);
        } else {
          this.DOM.master
            .querySelector(".fxPopinProductContainer")
            .appendChild(imgProduct);
        }
      } else {
        this.DOM.master
          .querySelector(".jsStandaloneImgReceptor")
          .appendChild(imgProduct);
      }
      setTimeout(() => {
        if (this.DOM.master.querySelector(".wps-icon-dropdown") != null) {
          this.DOM.master
            .querySelector(".wps-icon-dropdown")
            .querySelector("svg path")
            .setAttribute("d", "M1 1L9 9L17 1");
        }
      }, 1000);
    });

    const totalItems = this.DOM.relatedItems.length - 1;
    for (const [i, related] of this.DOM.relatedItems.entries()) {
      this.shopifyClient.product
        .fetchByHandle(related.getAttribute("data-handle"))
        .then((productRela) => {
          const imgProduct = new Image();
          imgProduct.src = productRela.images[0].src;
          related.querySelector("a").appendChild(imgProduct);
          if (totalItems == i) {
            if (this.DOM.splide != null) {
              this.slideshow();
            }
          }
        });
    }
  }

  slideshow() {
    let settings = {
      perPage: 2,
      gap: 0,
      padding: { left: 0, right: 108 },
    };
    if (Env.mobileCSS) {
      settings.perPage = 1;
      settings.gap = 0;
      settings.padding = { left: 0, right: 64 };
    }

    this.slideshowRelated = new Splide(this.DOM.splide, {
      arrows: true,
      perPage: settings.perPage,
      perMove: 1,
      gap: settings.gap,
      padding: settings.padding,
      type: "slide",
      autoplay: false,
      interval: 1000,
      rewind: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      pagination: true,
      arrowPath:
        "M17.6839 0.270508L26.4621 8.50004L17.6839 16.7296L16.3161 15.2705L22.4712 9.50004H0V7.50004H22.4712L16.3161 1.72958L17.6839 0.270508Z",
      easing: "cubic-bezier(0.645, 0.045, 0.355, 1.000)",
      speed: 700,
    }).mount();
  }

  intro() {
    if (Env.isVisiting == false) {
      gsap.fromTo(
        this.view.querySelectorAll(".fxInitProduct"),
        { autoAlpha: 0 },
        { autoAlpha: 1, delay: 1.8 }
      );
    }
    console.log("intro product");
  }
}

page = Barba.BaseView.extend({
  namespace: "product",
  node: undefined,
  onEnter: function () {
    this.node = new Page(this.container);
    Env.$html.classList.add(this.node.classView);
  },
  onEnterCompleted: function () {
    this.node.finalize();
  },
  onLeave: function () {
    this.node.destroy();
  },
  onLeaveCompleted: function () {
    if (this.container.getAttribute("data-namespace") != this.namespace) {
      Env.$html.classList.remove(this.node.classView);
      Env.$html.classList.remove("standalone-product");
    }
  },
});

export default page;
