import Channels from '../base/channels'
import axios from 'axios'
import gsap from 'gsap'


export default class ShopFilter
{
  constructor(view)
  {
    this.DOM = {}
    this.DOM.view = view
    this.DOM.receptor = view.querySelector('.jsXHR-receptor')
    this.DOM.filterTriggers = view.querySelectorAll('.jsFilterTrigger')
    this.DOM.reset = view.querySelector('.jsFilterReset')
    this.setup()
  }

  events()
  {

    for(let btn of this.DOM.filterTriggers) {
      btn.addEventListener('click',()=>{
        for(let btnClean of this.DOM.filterTriggers) {
          btnClean.classList.remove('is-active')
          btnClean.classList.remove('tw-text-black')
        }
        btn.classList.add('is-active')
        btn.classList.add('tw-text-black')
        this.filter()
        this.DOM.reset.classList.remove('tw-text-black')
        this.DOM.reset.classList.add('tw-text-gerrard')
      })
    }

    this.DOM.reset.addEventListener('click',()=>{
      this.DOM.reset.classList.add('tw-text-black')
      this.reset()
      this.filter()
      this.DOM.reset.classList.add('tw-text-black')
      this.DOM.reset.classList.remove('tw-text-gerrard')
      // setTimeout(() => {
      //   this.DOM.reset.classList.remove('tw-text-black')
      // }, 3000);
    })
  }

  filter()
  {
    gsap.to(window,{ scrollTo:{y:0}, ease:'cubic.inOut', duration:.6 })
    const tl = gsap.timeline()
    let terms = []
    for(let btn of this.DOM.filterTriggers) {
      if(btn.classList.contains('is-active')) {
        terms.push(btn.getAttribute('data-request'))
      }
    }


    tl.add([
      gsap.to(
        this.DOM.receptor,
        {
          autoAlpha:0,
          ease:'cubic.out',
          duration:.5
        }
      )
    ])
    tl.add(()=>{
      axios.get(config.XHR_URL, {
        params: {
          'action':'load_products',
          'slug': terms
        }
      })
      .then( (response)=> {

        this.DOM.receptor.innerHTML = response.data
        const $itemsFx = this.DOM.receptor.querySelectorAll('.jsItemFx')
        const $items = this.DOM.receptor.querySelectorAll('.jsItem')
        gsap.set(this.DOM.receptor, { autoAlpha: 1})
        Channels.emit('shop::update',{ items:$items })
      })
      .catch(function (error) {
        console.log(error);
      })
    })

  }

  reset()
  {
    for(let btn of this.DOM.filterTriggers) {
      btn.classList.remove('is-active')
      btn.classList.remove('tw-text-black')
    }
  }


  setup()
  {
    this.events()
  }
}
