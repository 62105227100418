import Env from "../../base/env";
import gsap from "gsap";
import { ScrollTrigger } from "../../vendor/gsap-bonus-umd/ScrollTrigger";
import { SplitText } from "../../vendor/gsap-bonus-umd/SplitText";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
import { DescReveal, VisualParallax } from "../Common";

export default class FxGallery {
  constructor(view) {
    this.DOM = {
      view: view,
    };
    this.DOM.title = this.DOM.view.querySelector(".jsTitle");
    this.DOM.desc = this.DOM.view.querySelector(".jsDesc");
    this.DOM.visuals = this.DOM.view.querySelectorAll(".jsVisualCollection");
    // FX
    this.FX = {};

    this.setup();
  }

  animate() {
    // Reveal
    let offsetReveal = Env.mobileCSS ? "top 90%" : "top 80%";
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: this.DOM.title,
        start: offsetReveal,
      },
    });

    tl.add([
      gsap.fromTo(
        this.FX.splitTitleChild.chars,
        {
          y: "110%",
        },
        {
          y: "0%",
          duration: 1.5,
          stagger: 0.05,
          ease: Env.eases.soap,
        }
      ),
    ]);
    tl.add(() => {
      DescReveal(this.DOM.desc, this.DOM.desc);
    });

    // Visuals Gallery
    for (const [i, visual] of this.DOM.visuals.entries()) {
      let scrub = 1.3;
      if (i + 2 == 1) scrub = 0.7;
      const visualImg = visual.querySelector(".jsVisualSrc");
      // Reveal
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: visual,
          start: "top bottom+=200",
        },
      });
      if (Env.mobileCSS) {
        tl.timeScale(1.5);
      }
      let posEnd = Env.framework.toolbox.getRandomInt(0, 1);
      if (posEnd < 1) {
        posEnd = "-101%";
      } else {
        posEnd = "101%";
      }
      let randomType = Env.framework.toolbox.getRandomInt(0, 1);
      let typeOfTween = ["x", "y"];
      const choiceTween = typeOfTween[randomType];
      let fromPattern = {};
      let toPattern = {};
      fromPattern[choiceTween] = 0;
      toPattern[choiceTween] = posEnd;
      toPattern["ease"] = Env.eases.soap;
      toPattern["duration"] = 2;
      toPattern["delay"] = 0.5;

      tl.add([
        gsap.fromTo(
          visualImg,
          {
            scale: 1.5,
          },
          {
            scale: 1.2,
            ease: "quint.out",
            duration: 1,
            delay: 1,
          }
        ),
        gsap.fromTo(visual.querySelector(".pattern"), fromPattern, toPattern),
      ]);

      VisualParallax(visual, visualImg, i);
    }
  }

  prepare() {
    this.FX.splitTitleParent = new SplitText(this.DOM.title, {
      type: "chars",
      charsClass: "tw-overflow-hidden jsParent",
    });
    this.FX.splitTitleChild = new SplitText(this.DOM.title, { type: "chars" });
  }

  setup() {
    this.prepare();
    this.animate();
  }
}
