import Env from "../../base/env";
import gsap from "gsap";
import { ScrollTrigger } from "../../vendor/gsap-bonus-umd/ScrollTrigger";
import { SplitText } from "../../vendor/gsap-bonus-umd/SplitText";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
import { animCircleBg, animDescCta } from "../Common";

export default class Hotel {
  constructor(view, fnComplete) {
    //? DOM
    this.DOM = {};
    this.DOM.view = view;
    this.DOM.fillBg = this.DOM.view.querySelector(".fill-bg");
    this.DOM.titleSVG = this.DOM.view.querySelector(".title-svg");
    this.DOM.desc = this.DOM.view.querySelector(".home-hotel-desc");
    this.DOM.cta = this.DOM.view.querySelectorAll(".home-hotel-cta");
    this.DOM.loop = this.DOM.view.querySelector(".loop");
    this.setup();
  }

  animate() {
    //? Fill BG
    animCircleBg(this.DOM.fillBg, this.DOM.view);

    //? Title SVG
    gsap.fromTo(
      this.DOM.titleSVG,
      {
        rotate: -50,
      },
      {
        rotate: 25,
        scrollTrigger: {
          trigger: this.DOM.view,
          start: "top bottom",
          end: `bottom top+=${Env.uiHeight * 2}`,
          scrub: Env.scrubTiming,
        },
      }
    );

    //? Desc
    animDescCta(this.DOM.desc, this.DOM.cta[0]);
    animDescCta(this.DOM.desc, this.DOM.cta[1]);

    //? Loop
    gsap.fromTo(
      this.DOM.loop,
      {
        y: 300,
      },
      {
        y: 0,
        ease: "power3.out",
        duration: 1,
        scrollTrigger: {
          trigger: this.DOM.loop,
          start: "top bottom",
        },
      }
    );
  }

  setup() {
    this.animate();
  }
}
