'use strict';
import Barba from 'barba.js';
import Env from '../base/env.js';
import Channels from '../base/channels'
import gsap from 'gsap'
import drawSVG from '../vendor/gsap-bonus-umd/DrawSVGPlugin'
import { ScrollTrigger } from "../vendor/gsap-bonus-umd/ScrollTrigger";
gsap.registerPlugin(drawSVG)
gsap.registerPlugin(ScrollTrigger)
import FxHerozone from '../fx/Restaurant/Herozone'
import FxSchedules from '../fx/Restaurant/Schedules'
import { CardRequests } from '../fx/Common'
import FxGallery from '../fx/Restaurant/Gallery'
import lottie from 'lottie-web'
import LoopData from '../datas/transition.json'

let page

class Page
{
  constructor(view='')
  {
      this.view = view
      this.DOM = {}
      this.DOM.view = view
      this.DOM.schedulesContainer = this.DOM.view.querySelector('.restaurant-schedules')
      this.DOM.days = this.DOM.view.querySelectorAll('.jsScheduleDay')
      this.DOM.loops = this.DOM.view.querySelectorAll('.jsLoop')
      this.classView = 'currentView--restaurant'
      //
      this.FX = {
        gallerys: []
      }
      // Launch Fn
      this.funcLaunch = this.intro.bind(this)
      if(Env.framework.UI.launcher.LAUNCHED == false) this.channels()
  }

  activeDay()
  {
    const today = new Date()
    let getDay = today.getDay()
    let currentDay

    getDay--
    if(getDay == -1) getDay = 6
    currentDay = this.DOM.days[getDay]

    const tl = gsap.timeline({
      scrollTrigger:{
        trigger:this.DOM.schedulesContainer,
        start:'top 80%',
        end:'top 0',
      }
    })

    currentDay.classList.add('is-active')

    tl.add([
      gsap.fromTo(
        currentDay.querySelector('.animation svg path'),
        {
          drawSVG:"0%",
          autoAlpha:1
        },
        {
          duration: 2,
          drawSVG:"100%",
          ease:'power3.inOut',

        }
      )
    ])

    if(Env.mobileCSS) {
      const coord = currentDay.getBoundingClientRect()
      this.DOM.schedulesContainer.scrollTo(coord.x - coord.width,0)
    }
}

  channels()
  {
      Channels.on('launcher::exit',this.funcLaunch)
  }

  destroy()
  {
      Channels.removeListener('launcher::exit', this.funcLaunch);
      if(typeof this.schedules != 'undefined') {
        this.schedules.destroy()
      }
  }

  finalize()
  {
      setTimeout(() => {
        if(Env.isVisiting === true) {
          setTimeout(() => {
            this.intro()
          }, Env.timingIntro);
        }
        this.fx()
      }, 200);
  }

  fx()
  {
    this.schedules = new FxSchedules(this.DOM.view,()=>{
      this.activeDay()
    })
    for(let gallery of this.DOM.view.querySelectorAll('.jsFxGallery')) {
      const instance = new FxGallery(gallery)
      this.FX.gallerys.push(instance)
    }
    //! CardRequests
    CardRequests(this.DOM.view.querySelector('.jsCardRequest'))
    //! Loop Animation
    this.loopAnimation()
  }

  loopAnimation()
  {
    for(let loop of this.DOM.loops) {
      const params = {
        container: loop,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LoopData
      };
      const instanceLoop = lottie.loadAnimation(params);
      instanceLoop.setSpeed(1.6)
    }

  }

  intro()
  {
      new FxHerozone(this.DOM.view.querySelector('.jsHerozoneView'))

  }
}

page = Barba.BaseView.extend({
  namespace: 'restaurant',
  node: undefined,
  onEnter: function() {
      this.node = new Page(this.container)
      Env.$html.classList.add(this.node.classView)
  },
  onEnterCompleted:function()
  {
      this.node.finalize()
  },
  onLeave: function()
  {
      this.node.destroy()
  },
  onLeaveCompleted: function()
  {
      if(this.container.getAttribute('data-namespace') != this.namespace)
      {
          Env.$html.classList.remove(this.node.classView)
      }
  }
});

export default page;
