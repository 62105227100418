import Env from '../../base/env'
import gsap from 'gsap'
import { ScrollTrigger } from "../../vendor/gsap-bonus-umd/ScrollTrigger";
import { SplitText } from "../../vendor/gsap-bonus-umd/SplitText";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
import { animCircleBg,animDescCta,ShopItemsReveal } from '../Common'

export default class Hotel
{
  constructor(view,fnComplete)
  {
    //? DOM
    this.DOM = {}
    this.DOM.view = view
    this.DOM.fillBg = this.DOM.view.querySelector('.fill-bg')
    this.DOM.titleSVG = this.DOM.view.querySelector('.title-svg')
    this.DOM.desc = this.DOM.view.querySelector('.home-shop-desc')
    this.DOM.cta = this.DOM.view.querySelector('.home-shop-cta')
    this.DOM.items = this.DOM.view.querySelectorAll('.jsItemFx')
    this.DOM.slideshowMobile = this.DOM.view.querySelector('.jsSlideshowShop')

    this.setup()
  }

  animate()
  {
    //? Fill BG
     animCircleBg(this.DOM.fillBg,this.DOM.view)

    //? Desc
    animDescCta(this.DOM.desc,this.DOM.cta)

    //? Title SVG
    gsap.fromTo(
      this.DOM.titleSVG,
      {
        rotate:-50,
      },
      {
        rotate:25,
        scrollTrigger: {
          trigger:this.DOM.view,
          start:'top bottom',
          end:`bottom top+=${Env.uiHeight * 2}`,
          scrub:Env.scrubTiming
        }
      }
    )

    //? Shop Mobile
    gsap.fromTo(
      this.DOM.slideshowMobile,
      {
        autoAlpha:0,
        y:100
      },
      {
        autoAlpha:1,
        y:0,
        ease:'quint.out',
        duration:2,
        scrollTrigger:{
          trigger:this.DOM.slideshowMobile,
          start:'top 90%'
        }
      }
    )

    //? Items
    ShopItemsReveal(this.DOM.items)
  }

  setup()
  {
    this.animate()
  }
}
