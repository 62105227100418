import Env from '../base/env'
import gsap from 'gsap'
import { ScrollTrigger } from "../vendor/gsap-bonus-umd/ScrollTrigger";
// import { SplitText } from "../vendor/gsap-bonus-umd/SplitText";
gsap.registerPlugin(ScrollTrigger);
// gsap.registerPlugin(SplitText);

export default class UIManager
{
  constructor(view)
  {
    this.DOM = {}
    this.DOM.view = view
    this.DOM.listeners = this.DOM.view.querySelectorAll('.jsUI-listener')
    this.arrays = {}
    this.arrays.listeners = []
    this.arrays.colorsLight = ['#ffffff','#F8EBDE','#ED1C32']
    this.setup()
  }

  detectColor(color)
  {
    const detect = this.arrays.colorsLight.includes(color)
    if(detect) {
      Env.$html.classList.add('darken-ui')
    } else {
      Env.$html.classList.remove('darken-ui')
    }
  }

  destroy()
  {
    Env.framework.UI.varsCSS()
    for(let listener of this.arrays.listeners) {
      listener.kill(false)
    }
  }

  manage()
  {
    for(let element of this.DOM.listeners)
    {
      const doc = document.documentElement
      const colorIn = element.getAttribute('data-bg-in')
      const colorOut = element.getAttribute('data-bg-out')
      const dataStart = element.getAttribute('data-start')
      let offsetStart = 'top top'
      if(dataStart != null) {
        offsetStart = `top top+=${dataStart}`
      }
      const listener = ScrollTrigger.create({
        trigger:element,
        start:offsetStart,
        end:'bottom top',
        onEnter:()=>{
          doc.style.setProperty('--color-ui', colorIn)
          this.detectColor(colorIn)
        },
        onEnterBack:()=>{
          doc.style.setProperty('--color-ui', colorIn)
          this.detectColor(colorIn)
        },
        onLeave:()=>{
          // console.log('on leave');
        },
        onLeaveBack:()=>{
          if(colorOut != null) {
            doc.style.setProperty('--color-ui', colorOut)
            this.detectColor(colorOut)
          }
          // console.log('on leave back',colorOut);
        },

      })
      this.arrays.listeners.push(listener)
    }
  }


  setup()
  {
    this.manage()
  }
}
