'use strict';

// MODULES
import OuterHeaven from './base/outerheaven.js';
import BarbaPopin from './components/BarbaPopin.js';
import BrowserDetection from './base/detection.js';
import Env from './base/env.js';
import Toolbox from './base/toolbox.js';
import Channels from './base/channels.js';
import MainTransition from './transition/TransitionMain'
import Home from './view/home.js';
import Restaurant from './view/restaurant';
import Hotel from './view/hotel.js';
import Shop from './view/shop.js';
import Product from './view/product.js';
import Contact from './view/contact.js';
import Page from './view/page.js';
import UI from './view/ui.js';

import gsap from 'gsap'
import { CustomEase } from "./vendor/gsap-bonus-umd/CustomEase.js";
gsap.registerPlugin(CustomEase);


document.addEventListener('DOMContentLoaded', function()
{
    //? Starter
    Env.framework.toolbox = new Toolbox();
    new BrowserDetection();
    window.addEventListener('resize',function() {
        clearTimeout(window.resizedFinished);
        window.resizedFinished = setTimeout(function(){
            Channels.emit('window::smartresize')
        }, 200);
    });

    //? Detect hashchange
    window.addEventListener('hashchange',function(){
      Channels.emit('window::hashchange')
    })

    Env.eases = {
      soap:CustomEase.create("soap", "M0,0 C0.84,0 0.16,1 1,1")
    }

    //? Views
    Env.framework.UI = new UI()
    Env.framework.MainTransition = new MainTransition()
    Env.framework.popin = new BarbaPopin();
    Home.init()
    Restaurant.init()
    Hotel.init()
    Shop.init()
    Product.init()
    Contact.init()
    Page.init()



    //? Signature
    if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
        var args = ['\n %c with <3 by @jcsuzanne \u2730 http://www.jcsuzanne.com/ \n\n','background: #ffcc33; padding:5px 0;color: #333333;'];
        window.console.log.apply(console, args);
    } else if (window.console) {
        window.console.log('@jcsuzanne - http://www.jcsuzanne.com/');
    }

    //? Start Barba
    new OuterHeaven();

    //? PWA
    if(config.env === "production") {
        navigator.serviceWorker.controller?console.log("[PWA Builder] active service worker found, no need to register"):navigator.serviceWorker.register(config.pathRoot+"pwa.js",{scope:config.pathRoot}).then(function(e){console.log("Service worker has been registered for scope:"+e.scope)});
    }
});

