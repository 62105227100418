export function renderItem(container,data)
{
  if(data != null) {
    const imgProduct = new Image()
    imgProduct.classList.add('tw-w-full')
    imgProduct.classList.add('tw-h-full')
    imgProduct.classList.add('tw-object-cover')
    imgProduct.classList.add('tw-relative')
    imgProduct.classList.add('tw-z-0')
    if(data.images != null) {
      imgProduct.src = data.images[0].src
      container.appendChild(imgProduct)
    }

    //
    const drawPrice =  renderPrice(data)
    container.querySelector('.fxPrice').innerHTML = drawPrice
  }

}

export function renderPrice(data)
{
  return new Intl.NumberFormat(config.lang.replace('_','-'), { style: 'currency', currency: data.variants[0].priceV2.currencyCode }).format(data.variants[0].priceV2.amount)
}
