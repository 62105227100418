import Env from "../base/env.js";
import Channels from "../base/channels.js";
import gsap from "gsap";
import SplitText from "../vendor/gsap-bonus-umd/SplitText";
import ScrollToPlugin from "../vendor/gsap-bonus-umd/ScrollToPlugin";
gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollToPlugin);
import * as Utils from "../vendor/utils";
import { AnimLetterIn, AnimLetterOut } from "../fx/Common";

class MainnavTouch {
  constructor() {
    if (Env.$mainNav == null) {
      return false;
    }
    this.view = Env.$mainNav;
    this.$cache = document.getElementById("jsCache--mainnav");
    this.$triggers = Env.$body.querySelectorAll(".jsMainnav--trigger");
    this.$curtain = this.view.querySelectorAll(".jsCurtain path");
    this.$primaryItems = this.view.querySelectorAll(".jsMainnav li");
    this.$booking = this.view.querySelector(".jsBooking");
    this.$close = this.view.querySelector(".jsClose");
    this.$cursor = document.querySelector(".jsMainavCursor");
    this.settingsCurtain = { duration: 1.2 };
    this.txtSplitter = [];
    this.txtAnimators = [];
    this.allChars = [];
    this.svgPath = {
      arrow: {
        end: "M28.8 16.0001L20.2666 7.4668M28.8 16.0001L20.2666 24.5335M28.8 16.0001H2.1333",
        start:
          "M 28.8 16.0001 L 28.8 15.99 M 28.8 16.0001 L 28.8 16.009 M 28.8 16.0001 H 28.8",
      },
    };
    this.mousePos = this.lastMousePos = { x: 0, y: 0 };
    // Flags
    this.IS_ANIMATING = false;
    // Fns
    this.fnMousemove = this.cursorMove.bind(this);
    this.adjustTexts();
    this.events();
    this.positionFooter();
    this.raf = requestAnimationFrame(() => this.render());
  }

  adjustTexts() {
    let i = 0;
    for (let $item of this.$primaryItems) {
      const $getLink = $item.querySelector("a");
      const adjustText = new SplitText($getLink, { type: "chars" });
      if (i % 2 == 0) {
        // gsap.set($getLink,{ x : '-100%'})
        // $getLink.setAttribute('data-x','-100%')
        this.txtSplitter.push(adjustText.chars);
      } else {
        // gsap.set($getLink,{ x : '100%'})
        // $getLink.setAttribute('data-x','100%')
        this.txtSplitter.push(adjustText.chars.reverse());
      }

      this.txtAnimators.push($getLink);
      i++;
    }
  }

  events() {
    var self = this;

    for (const e of this.$triggers) {
      e.addEventListener(
        "click",
        () => {
          if (this.IS_ANIMATING == false) {
            this.toggle();
          }
          return false;
        },
        false
      );
    }

    Channels.on("mainnav::close", () => {
      this.close();
    });

    Channels.on("window::smartresize", () => {
      this.positionFooter();
      if (Env.mobileCSS === false) {
        // gsap.set(this.$primaryItems,{ clearProps : 'all' })
      }
    });

    if (Env.mobileCSS == false) {
      this.hover();
    }

    this.$close.addEventListener("click", () => {
      this.close();
    });

    this.$cache.addEventListener("click", () => {
      this.close();
    });

    window.addEventListener("mousemove", this.fnMousemove);
  }

  cursorMove(ev) {
    const viewport = Env.framework.toolbox.getViewport();
    const coords = this.view.getBoundingClientRect();
    let mousePos = Utils.getMousePos(ev);
    mousePos.x = mousePos.x - coords.width;
    this.mousePos = mousePos;
  }

  cursorGrow() {
    gsap.to(this.$cursor, {
      duration: 0.5,
      scale: 1,
      ease: "quint.out",
    });
  }

  cursorReduce() {
    gsap.to(this.$cursor, {
      duration: 0.5,
      scale: 0,
      ease: "quint.out",
    });
  }

  hover() {
    for (const [i, $entry] of this.$primaryItems.entries()) {
      const $arrow = $entry.querySelector(".shape svg path");
      const txtAnimator = this.txtSplitter[i];
      $entry.addEventListener("mouseenter", () => {
        gsap.fromTo(
          $arrow,
          {
            autoAlpha: 1,
          },
          {
            duration: 0.4,
            attr: { d: this.svgPath.arrow.end },
            ease: "power2.inOut",
            delay: 0,
          }
        );
        AnimLetterIn(txtAnimator);
        this.cursorGrow();
      });
      $entry.addEventListener("mouseleave", () => {
        gsap.to($arrow, {
          duration: 0.4,
          attr: { d: this.svgPath.arrow.start },
          ease: "power2.inOut",
          delay: 0,
          onComplete: () => {
            gsap.set($arrow, { autoAlpha: 0 });
          },
        });
        AnimLetterOut(txtAnimator);
        this.cursorReduce();
      });
    }
  }

  getStatus() {
    return this.view.classList.contains("is--active");
  }

  close(funcStart = null, funcComplete = null) {
    this.out(
      () => {
        this.closeEventStart();
        if (typeof funcStart == "function" || funcStart != null) funcStart();
      },
      () => {
        this.closeEventEnd();
        if (typeof funcComplete == "function" || funcComplete != null)
          funcComplete();
      }
    );
  }

  closeEventStart() {
    Channels.emit("mainnav::closing");
    this.IS_ANIMATING = true;
    Env.$html.classList.remove("mainnav--opening");
    this.view.classList.remove("is--active");
    Env.$html.classList.remove("mainnav--isOpened");
    Env.$html.classList.add("mainnav--isLeaving");
    setTimeout(() => {
      Env.$html.classList.remove("ui--isUpdating");
    }, 1500);
  }

  closeEventEnd() {
    this.IS_ANIMATING = false;
    Env.$html.classList.remove("mainnav--isLeaving");
  }

  in(funcStart = "", funcComplete = "") {
    let timeline = gsap.timeline({
      onStart: () => {
        const controller = Env.framework.toolbox.getController();
        this.status = "animating";
        setTimeout(() => {
          Env.$html.classList.add("ui--isUpdating");
        }, 150);
        if (typeof funcStart == "function" || funcStart != null) funcStart();
      },
      onComplete: () => {
        this.status = "in";
        Env.$html.classList.add("mainnav--opening");
        if (typeof funcComplete == "function" || funcComplete != null)
          funcComplete();
      },
    });

    timeline.add(() => {
      gsap.set(this.$primaryItems, { autoAlpha: 1 });
    });
    timeline.add(
      [
        gsap.to(this.$curtain, {
          duration: this.settingsCurtain.duration,
          attr: { d: Env.svgCurtain.middle },
          ease: "quint.inOut",
        }),
      ],
      "+=.5"
    );
    timeline.add(
      [
        gsap.to(this.$curtain, {
          duration: this.settingsCurtain.duration,
          attr: { d: Env.svgCurtain.init },
          ease: "quint.out",
        }),
        gsap.fromTo(
          this.$close,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            duration: 1,
            ease: "cubic.out",
          }
        ),
        gsap.to(this.$cursor, {
          duration: 1,
          scale: 0,
          ease: "quint.out",
        }),
      ],
      `-=${this.settingsCurtain.duration / 2}`
    );

    for (let i = 0; i < this.txtAnimators.length; i++) {
      const timing = i == 0 ? "-=1.25" : ">-=.8";
      const DOM = {};
      DOM.txtSplit = this.txtSplitter[i];
      DOM.animator = this.txtAnimators[i];
      DOM.parent = DOM.animator.parentElement;
      DOM.line = DOM.parent.querySelectorAll(".line");
      timeline.add(
        [
          gsap.fromTo(
            DOM.txtSplit,
            {
              y: 100,
            },
            {
              y: 0,
              ease: "quint.out",
              duration: 0.8,
              stagger: 0.05,
              delay: 0.6,
            }
          ),
          gsap.fromTo(
            DOM.line,
            {
              scaleX: 0,
              transformOrigin: "right top",
            },
            {
              scaleX: 1,
              duration: 1,
              ease: "quint.inOut",
              stagger: 0.2,
            }
          ),
        ],
        timing
      );
    }
    timeline.add(
      [
        gsap.fromTo(
          this.$booking,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            duration: 1,
            ease: "cubic.out",
          }
        ),
      ],
      "-=.5"
    );
    timeline.add(() => {
      Env.$html.classList.add("mainnav--clickable");
    });
  }

  out(funcStart = "", funcComplete = "") {
    let timeline = gsap.timeline({
      onStart: () => {
        this.status = "animating";
        Env.$html.classList.remove("mainnav--clickable");
        if (typeof funcStart == "function" || funcStart != null) funcStart();
      },
      onComplete: () => {
        const controller = Env.framework.toolbox.getController();
        this.status = "out";
        if (typeof funcComplete == "function" || funcComplete != null)
          funcComplete();
      },
    });

    for (let i = 0; i < this.txtAnimators.length; i++) {
      const timing = i == 0 ? "" : "-=.8";
      const DOM = {};
      DOM.txtSplit = this.txtSplitter[i];
      DOM.animator = this.txtAnimators[i];
      DOM.parent = DOM.animator.parentElement;
      DOM.line = DOM.parent.querySelectorAll(".line");
      timeline.add(
        [
          gsap.to(DOM.txtSplit, {
            y: 100,
            rotate: 0,
            ease: "quint.inOut",
            duration: 0.5,
            stagger: 0.02,
          }),
          gsap.to(DOM.line, {
            scaleX: 0,
            duration: 0.8,
            ease: "quint.inOut",
          }),
        ],
        timing
      );
    }
    timeline.add(
      [
        gsap.to(this.$booking, {
          autoAlpha: 0,
          duration: 1,
          ease: "cubic.out",
        }),
        gsap.to(this.$close, {
          autoAlpha: 0,
          duration: 1,
          ease: "cubic.out",
        }),
        gsap.to(this.$cursor, {
          duration: 0.5,
          scale: 0,
          ease: "quint.out",
        }),
      ],
      "-=.5"
    );
    timeline.add(
      [
        gsap.to(this.$curtain, {
          duration: this.settingsCurtain.duration,
          attr: { d: Env.svgCurtain.middle },
          ease: "quint.inOut",
        }),
      ],
      "-=.85"
    );
    timeline.add(
      [
        gsap.to(this.$curtain, {
          duration: this.settingsCurtain.duration / 2,
          attr: { d: Env.svgCurtain.end },
          ease: "quint.out",
        }),
      ],
      `-=${this.settingsCurtain.duration / 2}`
    );
    timeline.add(() => {
      gsap.set(this.$primaryItems, { autoAlpha: 0 });
    });
  }

  positionFooter() {
    const viewport = Env.framework.toolbox.getViewport();
    const ul = this.view.querySelector(".jsMainnav > ul");
    const dimensions = ul.getBoundingClientRect();
    const globalHeight = dimensions.height + dimensions.top;
    const navHeight = viewport.height - globalHeight;

    const doc = document.documentElement;
    doc.style.setProperty("--app-nav-bottom-height", `${navHeight}px`);
    doc.style.setProperty("--app-nav-bottom-y", `${globalHeight}px`);
  }

  toggle(_status) {
    if (typeof _status === "undefined")
      _status = this.view.classList.contains("is--active");

    if (!!_status) {
      this.close();
    } else {
      this.in(
        () => {
          this.IS_ANIMATING = true;
          this.view.classList.add("is--active");
          Env.$html.classList.add("mainnav--isOpened");
        },
        () => {
          this.IS_ANIMATING = false;
        }
      );
    }
  }

  render() {
    this.lastMousePos.x = Utils.lerp(
      this.lastMousePos.x || this.mousePos.x,
      this.mousePos.x,
      0.2
    );
    this.lastMousePos.y = Utils.lerp(
      this.lastMousePos.y || this.mousePos.y,
      this.mousePos.y,
      0.2
    );
    if (this.$cursor != null) {
      gsap.set(this.$cursor, {
        left: this.lastMousePos.x - 60,
        top: this.lastMousePos.y - 60,
      });
    }

    requestAnimationFrame(() => this.render());
  }

  reset() {
    Env.$html.classList.remove("mainnav--clickable");
    gsap.set(this.$primaryItems, { autoAlpha: 0 });
    gsap.set(this.$close, { autoAlpha: 0 });
    gsap.set(this.$booking, { autoAlpha: 0 });
    gsap.set(this.$cursor, { scale: 0 });
    gsap.set(this.$curtain, { attr: { d: Env.svgCurtain.end } });
    for (let i = 0; i < this.txtAnimators.length; i++) {
      const DOM = {};
      DOM.txtSplit = this.txtSplitter[i];
      DOM.animator = this.txtAnimators[i];
      DOM.parent = DOM.animator.parentElement;
      DOM.line = DOM.parent.querySelectorAll(".line");
      gsap.set(DOM.txtSplit, { y: 100 });
      gsap.set(DOM.line, { scaleX: 0 });
    }
    this.closeEventStart();
    this.closeEventEnd();
  }
}

export default MainnavTouch;
