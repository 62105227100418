'use strict';
import Barba from 'barba.js';
import Env from '../base/env.js';
import Channels from '../base/channels'
import HoverMarquee from '../fx/HoverMarquee.js';
import ShopFilter from '../components/ShopFilter'
import MouseTrail from '../fx/MouseTrail'
import Client from 'shopify-buy';
import { renderItem } from '../components/Helpers'
import { ShopItemsReveal,RefreshFooterTrigger } from '../fx/Common'
import gsap from 'gsap'
import { DrawSVGPlugin } from "../vendor/gsap-bonus-umd/DrawSVGPlugin";
gsap.registerPlugin(DrawSVGPlugin);

let page

class Page
{
    constructor(view='')
    {
        this.view = view
        this.DOM = {}
        this.DOM.view = view
        this.DOM.items = view.querySelectorAll('.jsItem')
        this.DOM.itemsFx = view.querySelectorAll('.jsItemFx')
        this.DOM.filterTriggers = view.querySelectorAll('.jsFilterTrigger')
        this.DOM.shopView = this.DOM.view.querySelector('.jsShopView')
        this.DATAS_SHOP = []
        this.classView = 'currentView--shop'
        this.FILTERING = false
        // Shopify
        this.shopifyClient = Client.buildClient({
          domain: Env.shopifyDomain,
          storefrontAccessToken: Env.storefrontAccess
        });
        // Launch Fn
        this.funcLaunch = this.preload.bind(this)
        this.funcFxUpdate = this.fxUpdate.bind(this)
        if(Env.framework.UI.launcher.LAUNCHED == false) this.channels()
        this.events()
    }

    channels()
    {
        Channels.on('launcher::exit',this.funcLaunch)

    }

    events()
    {
      Channels.on('shop::update',this.funcFxUpdate)
    }

    destroy()
    {
        Channels.removeListener('launcher::exit', this.funcLaunch);
        Channels.removeListener('shop::update', this.funcFxUpdate);

    }

    finalize()
    {
        setTimeout(() => {
            if(Env.isVisiting === true) {
              setTimeout(() => {
                // this.intro()
                this.loadShopifyDatas(this.DOM.items)
              }, Env.timingIntro);
            }
            this.varsCSS()
            setTimeout(() => {
              this.fxInit()
            }, 500);

        }, 200);
    }

    fxInit()
    {

      new HoverMarquee(this.DOM.items)
      this.shopFilter = new ShopFilter(this.DOM.view)
      // if(Env.mobileCSS == false) {
      //   Env.mousetrailAPI = new MouseTrail(this.DOM.view)
      //   Env.mousetrailAPI.start()
      // }
      // setTimeout(() => {
      //   if(typeof Env.mousetrailAPI != 'undefined' && Env.mousetrailAPI != false) {
      //     Env.mousetrailAPI.show()
      //   }
      // },500);
    }

    fxUpdate(params)
    {
      this.FILTERING = true
      new HoverMarquee(params.items)
      // Draw Image / Price
      for(let item of params.items) {
        const handle = item.getAttribute('data-handle');
        const data = this.DATAS_SHOP[handle]
        renderItem(item,data)
      }
      this.DOM.shopView = this.DOM.view.querySelector('.jsShopView')
      Env.framework.UI.listenPopinTrigger(this.DOM.shopView)
      gsap.set(this.DOM.shopView, { autoAlpha:1 })
      const itemsFx = this.DOM.view.querySelectorAll('.jsItemFx')
      this.DOM.itemsFx = itemsFx
      ShopItemsReveal(itemsFx)
      RefreshFooterTrigger()
    }

    loadShopifyDatas(items)
    {
      const totalItems = items.length - 1;
      for (const [i, item] of items.entries()) {
        const handle = item.getAttribute('data-handle');
        this.shopifyClient.product.fetchByHandle(handle).then((product) => {
          this.DATAS_SHOP[handle] = product
          if(product != null) {
            renderItem(item,product)
          } else {
            this.DOM.shopView.removeChild(item.parentElement)
          }
          if(totalItems == i) {
            this.intro()
          }
        });
      }
    }

    preload()
    {
      this.loadShopifyDatas(this.DOM.items)
    }


    intro()
    {
      const lineMobile = this.DOM.view.querySelector('.jsMobileLine')
      const shopView = this.DOM.view.querySelector('.jsShopView')
      const filters = this.DOM.view.querySelectorAll('.fxFilter')
      const desc = this.DOM.view.querySelector('.fxDesc')
      const tl = gsap.timeline()

      tl.add([
        gsap.fromTo(
          desc,
          {
            autoAlpha:0,
            y:25
          },
          {
            autoAlpha:1,
            y:0,
            ease:'quint.out',
            duration:1.2
          }
        ),
        gsap.fromTo(
          filters,
          {
            autoAlpha:0,
            y:25
          },
          {
            autoAlpha:1,
            y:0,
            ease:'quint.out',
            duration:1,
            stagger:.1,
            onStart:()=>{
              setTimeout(() => {
                gsap.set(this.DOM.shopView , { autoAlpha:1 })
                gsap.set(lineMobile, { autoAlpha:1 })
                ShopItemsReveal(this.DOM.itemsFx)
                gsap.fromTo(
                  lineMobile.querySelector('path'),
                  {
                    drawSVG:'0% 0%'
                  },
                  {
                    drawSVG:'0% 100%',
                    duration:3,
                    ease:'quint.out'
                  }
                )
              }, 500);

            }
          }
        )
      ])

    }

    varsCSS()
    {
      const doc = document.documentElement
      doc.style.setProperty('--color-ui', `#ffffff`)
    }
}

page = Barba.BaseView.extend({
    namespace: 'shop',
    node: undefined,
    onEnter: function() {
        this.node = new Page(this.container)
        Env.$html.classList.add(this.node.classView)
    },
    onEnterCompleted:function()
    {
        this.node.finalize()
    },
    onLeave: function()
    {
        this.node.destroy()
    },
    onLeaveCompleted: function()
    {
        if(this.container.getAttribute('data-namespace') != this.namespace)
        {
            Env.$html.classList.remove(this.node.classView)
        }
    }
});

export default page;
