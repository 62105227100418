import Env from "../../base/env";
import gsap from "gsap";
import { ScrollTrigger } from "../../vendor/gsap-bonus-umd/ScrollTrigger";
import { SplitText } from "../../vendor/gsap-bonus-umd/SplitText";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
import { DescReveal, VisualParallax } from "../Common";

export default class FxGallery {
  constructor(view) {
    this.DOM = {
      view: view,
    };
    this.DOM.title = this.DOM.view.querySelector(".jsTitle");
    this.DOM.desc = this.DOM.view.querySelector(".jsDesc");
    this.DOM.mainVisual = this.DOM.view.querySelector(".jsMainVisualContainer");
    this.DOM.mainVisualImg = this.DOM.mainVisual.querySelector("img");
    this.DOM.visuals = this.DOM.view.querySelectorAll(".jsVisualCollection");

    // FX
    this.FX = {};

    this.setup();
  }

  animate() {
    // Reveal
    let offsetReveal = Env.mobileCSS ? "top 90%" : "top 80%";
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: this.DOM.title,
        start: offsetReveal,
      },
    });
    tl.add([
      gsap.fromTo(
        this.FX.splitTitleChild.chars,
        {
          y: "110%",
        },
        {
          y: "0%",
          duration: 1,
          stagger: 0.05,
          ease: Env.eases.soap,
        }
      ),
    ]);
    let yStart = 200;
    if (Env.mobileCSS) {
      yStart = 75;
    }
    tl.add(
      [
        gsap.fromTo(
          this.DOM.mainVisual,
          {
            autoAlpha: 0,
            y: yStart,
          },
          {
            autoAlpha: 1,
            y: 0,
            ease: "quint.out",
            duration: 1,
          }
        ),
        gsap.fromTo(
          this.DOM.mainVisualImg,
          {
            scale: 1.5,
          },
          {
            scale: 1.2,
            ease: "quint.out",
            duration: 1,
          }
        ),
      ],
      "-=.8"
    );

    // Parallax Main
    const viewport = Env.framework.toolbox.getViewport();
    const offsetStart = "top 35%";
    const trigger = this.DOM.mainVisualImg;
    const scrub = 1;
    gsap.fromTo(
      this.DOM.mainVisualImg,
      {
        y: 25,
      },
      {
        y: -25,
        scrollTrigger: {
          trigger: trigger,
          start: offsetStart,
          end: `+=${this.DOM.mainVisualImg.getBoundingClientRect().height}px`,
          scrub: scrub,
        },
      }
    );
    // Desc Main
    if (this.DOM.desc != null) {
      DescReveal(this.FX.splitDesc.lines, this.DOM.desc);
    }

    // Visuals Gallery
    for (const [i, visual] of this.DOM.visuals.entries()) {
      let scrub = 1.3;
      if (i + 2 == 1) scrub = 0.7;
      const visualImg = visual.querySelector(".jsVisualSrc");
      // Reveal
      let offsetSetting = `top bottom+=${viewport.height / 2}`;
      if (Env.mobileCSS) {
        offsetSetting = "top bottom+=300";
      }
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: visual,
          start: offsetSetting,
          markers: false,
        },
      });
      if (Env.mobileCSS) {
        tl.timeScale(1.5);
      }

      tl.add([
        gsap.fromTo(
          visual,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            ease: Env.eases.soap,
            duration: 1.3,
            delay: 0.5,
          }
        ),
        gsap.fromTo(
          visual.parentElement,
          {
            y: 200,
          },
          {
            y: 0,
            ease: Env.eases.soap,
            duration: 1.3,
          }
        ),
        gsap.fromTo(
          visualImg,
          {
            scale: 1.5,
          },
          {
            scale: 1.2,
            ease: "quint.out",
            duration: 1,
            delay: 1,
          }
        ),
      ]);
      VisualParallax(visual, visualImg, i);
    }
  }

  prepare() {
    this.FX.splitTitleParent = new SplitText(this.DOM.title, {
      type: "chars",
      charsClass: "tw-overflow-hidden jsParent",
      specialChars: ["oo"],
    });
    this.FX.splitTitleChild = new SplitText(this.DOM.title, {
      type: "chars",
      specialChars: ["oo"],
    });

    if (this.DOM.title.querySelector(".jsSpecialChar") != null) {
      const specialChar = this.DOM.title.querySelector(".jsSpecialChar");
      const coord = specialChar.getBoundingClientRect();
      const elToChange = specialChar.querySelector(".jsParent");
      let mL = "-4vw";
      let mR = "-3.5vw";
      if (Env.mobileCSS) {
        mL = -20;
        mR = -20;
      }
      gsap.set(elToChange, {
        width: coord.width * 2,
        marginLeft: mL,
        marginRight: mR,
      });
    }

    if (this.DOM.desc != null) {
      this.FX.splitDesc = new SplitText(this.DOM.desc, { type: "lines" });
    }
  }

  setup() {
    this.prepare();
    this.animate();
  }
}
