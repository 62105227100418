import Barba from 'barba.js'
import Env from '../base/env'

let PopinTransition = Barba.BaseTransition.extend({
    start: function() {
        Promise
        .all([this.newContainerLoading, this.close()])
        .then(this.finish.bind(this))
    },
    close : function()
    {
        const deferred = Barba.Utils.deferred();
        Env.framework.popin.transitionClose(()=>{
            deferred.resolve();
        })
        return deferred.promise;
    },
    finish: function() {
        const newController = this.newContainer.getAttribute('data-namespace')

        // If product, open popin
        console.log('transition popin');
        if(newController == 'product') {
            Env.framework.popin.reset()
            Env.framework.popin.attach(this.newContainer,false)
            setTimeout(() => {
                Env.framework.popin.transitionOpen()
            }, 300);
            this.newContainer.parentElement.removeChild(this.newContainer)
            this.deferred.resolve();
        } else {
            this.newContainer.parentElement.removeChild(this.newContainer)
            this.deferred.resolve();
        }
    }
});

export default PopinTransition;
