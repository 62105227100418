import Env from '../../base/env'
import gsap from 'gsap'

export default class Herozone
{
  constructor(view)
  {
    //? DOM
    this.DOM = {}
    this.DOM.view = view
    this.DOM.herozone = this.DOM.view.querySelector('.jsHerozoneView')
    this.DOM.overlay = this.DOM.herozone.querySelector('.jsOverlay')
    this.DOM.logo = this.DOM.view.querySelector('.jsLogoView')
    this.DOM.curtain = this.DOM.view.querySelector('.jsCurtainView')
    this.DOM.curtainPaths = this.DOM.curtain.querySelectorAll('path')

    this.animate()
  }

  animate()
  {
    const tl = gsap.timeline()

    tl.add(()=>{
      gsap.set(this.DOM.logo,{ autoAlpha:1, scale:.8 })
    })
    tl.add([
      gsap.fromTo(
        this.DOM.logo.querySelectorAll('path'),
        {
          scale:0,
          transformOrigin:'center center'
        },
        {
          scale:1,
          ease:Env.eases.soap,
          duration:1,
          stagger:.05
        }
      )
    ])
    tl.add([
      gsap.to(
        this.DOM.curtainPaths,
        {
          duration:1,
          attr : { d: Env.svgCurtain.middle },
          ease: 'cubic.inOut'
        }
      ),
    ])
    tl.add([
      gsap.to(
        this.DOM.curtainPaths,
        {
          duration:1,
          attr : { d: Env.svgCurtain.end },
          ease: 'cubic.out',
          onComplete:()=>{
            gsap.set(this.DOM.curtain, { autoAlpha:0 })
          }
        }
      ),
      gsap.to(
        this.DOM.overlay,
        {
          autoAlpha:0,
          ease:'quint.out',
          duration:1
        }
      )
    ],'-=.5')
    tl.add([
      gsap.to(
        this.DOM.logo,
        {
          scale:1,
          ease:'power3.out',
          duration:1
        }
      ),
      gsap.fromTo(
        this.DOM.herozone.querySelectorAll('img'),
        {
          scale:1.3
        },
        {
          scale:1,
          ease:'power3.out',
          duration:1.3
        }
      )
    ],'-=1.3')
  }
}
