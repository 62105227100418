
import Env from '../base/env.js';
import Channels from '../base/channels.js';
import lozad from 'lozad'
import Launcher from '../view/launcher'
import MainnavTouch from '../ui/MainnavTouch'
import UISwitcher from '../ui/UISwitcher'
import ShopActions from '../components/ShopActions.js';
import { FooterFx,hoverMainButtons,DestroyFooterTrigger } from '../fx/Common'
import UIManager from '../fx/UIManager.js';
import gsap from 'gsap'
// import { enableScroll,disableScroll } from '../components/ScrollDisabler';


class UI
{
    constructor()
    {
      if(typeof history.scrollRestoration != 'undefined') {
          history.scrollRestoration = 'manual';
      }
      this.launcher = new Launcher()
      this.detectAppHeight()
      this.resetEnv();
      this.uiSwitch =  new UISwitcher()
      this.shopActions = new ShopActions()
      Env.framework.mainnav = new MainnavTouch()
      this.varsCSS()
      this.varsENV()
      const getCurrentView = document.querySelector('.barba-container')
      setTimeout(() => {
        Env.uiManagerAPI = new UIManager(getCurrentView)
        Env.uiFooterAPI = FooterFx(getCurrentView)
      }, 2000);

      if(Env.mobileCSS == false) {
        hoverMainButtons()
      }

      this.detectLinkToProducts(getCurrentView)
      this.listenPopinTrigger(getCurrentView)
      this.credits(getCurrentView)
    }

    appHeight()
    {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)

      const getScrollbarWidth = Env.framework.toolbox.getScrollbarWidth()
      doc.style.setProperty('--scrollbar-width', `${getScrollbarWidth}px`)
    }

    credits(view)
    {
      const trigger = document.querySelector('.jsCreditsTrigger')
      const hover = document.querySelector('.jsCreditsHover')
      if(trigger != null) {
        if(Env.mobileCSS) {
          trigger.addEventListener('click',()=>{
            hover.classList.toggle('is-active')
          })
          hover.addEventListener('click',()=>{
            hover.classList.remove('is-active')
          })
        } else {
          trigger.addEventListener('mouseenter',()=>{
            hover.classList.add('is-active')
          })
          hover.addEventListener('mouseleave',()=>{
            hover.classList.remove('is-active')
          })
        }
      }
    }

    detectAppHeight()
    {
      this.appHeight()
      Channels.on('window::smartresize',this.appHeight)
    }

    lazy()
    {
      const observer = lozad(); // lazy loads elements with default selector as '.lozad'
      observer.observe();
    }

    detectLinkToProducts(view)
    {
      const $triggers = view.querySelectorAll('a')
      const regex = new RegExp('/products/');
      for(let $trigger of $triggers) {
        const testStr = regex.test($trigger.getAttribute('href'))
        if(testStr) {
          $trigger.addEventListener('click',()=>{
              Env.popinModeClicked = true
          })
        }
      }
    }

    listenPopinTrigger(view)
    {
        const $triggers = view.querySelectorAll('.jsTriggerPopin')
        for(let i = 0; i < $triggers.length; i++) {
            const $trigger = $triggers[i]
            $trigger.addEventListener('click',()=>{
                Env.popinModeClicked = true
            })
        }
    }

    resetEnv()
    {
      Channels.on('statechange::before',function()
      {
        const patterns = 'hotel'
        const test = window.location.href.includes(patterns)
        if(test) {
          Env.$html.classList.add('transition-gold')
        }
        if(Env.uiManagerAPI != false){
          Env.uiManagerAPI.destroy()
        }
        DestroyFooterTrigger()
      })
      Channels.on('statechange::ready',(current,old,container)=>
      {
          // Env.$html.classList.remove('skin--isWhite')
          // Env.$html.classList.remove('skin--isDark')
          // Env.$mainNav.classList.remove('is--active');
          // Env.$html.classList.remove('mainnav--isOpened')
          Env.$html.classList.remove('darken-ui')
          Env.$html.classList.remove('show-uicache')

          if(old.namespace == 'shop' && current.namespace != 'product') {
            if(typeof Env.mousetrailAPI != 'undefined' && Env.mousetrailAPI != false) {
              console.log('destoy trail');
              Env.mousetrailAPI.destroy()
            }
          }
      })
      Channels.on('statechange::finalize',(params)=>
      {
          if(Env.popinModeClicked == false)
          {
            this.varsCSS()
            Env.$html.classList.remove('transition-gold')
            setTimeout(() => {
              Env.uiManagerAPI = new UIManager(params.view)
              Env.uiFooterAPI = FooterFx(params.view)
            }, 2000);
            this.detectLinkToProducts(params.view)
            this.listenPopinTrigger(params.view)
            this.credits(params.view)
          }

          Env.popinModeClicked = false

      })
    }

    trackerPopinProduct()
    {
      if(typeof window.gtag !== "undefined") {
        gtag('event', 'view_item', {
          'event_category': 'engagement',
          'non_interaction': false
        });
      }
    }

    varsCSS()
    {
      const doc = document.documentElement
      doc.style.setProperty('--color-ui', `transparent`)
    }

    varsENV()
    {
      Env.uiHeight = document.querySelector('.ui').getBoundingClientRect().height
    }

}

export default UI;
