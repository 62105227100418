import Env from '../../base/env'
import gsap from 'gsap'
import { ScrollTrigger } from "../../vendor/gsap-bonus-umd/ScrollTrigger";
import { SplitText } from "../../vendor/gsap-bonus-umd/SplitText";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

export default class Logo
{
  constructor(view,fnComplete)
  {
    //? DOM
    this.DOM = {}
    this.DOM.view = view
    this.DOM.titleP = this.DOM.view.querySelectorAll('.home-introduction-title p')
    this.DOM.desc = this.DOM.view.querySelector('.home-introduction-desc')
    this.DOM.gallery = this.DOM.view.querySelectorAll('.home-introduction-gallery li')
    //? FX
    this.FX = {}
    this.FX.settings = {
      ease:'power3.out',
      duration:1,
      stagger:.05,
      y: {
        from:250
      },
      delays: [0,.4,.8]
    }
    this.FX.titleP = []
    // Total
    // Fns
    this.fnComplete = fnComplete
    this.setup()
  }

  animate()
  {
    let offsetStart = 'top 20%'
    if(Env.mobileCSS) {
      offsetStart = 'top 70%'
    }
    this.stParams = {
      trigger:this.DOM.view,
      start:offsetStart
    }

    let offsetStartDesc = 'top 65%'
    if(Env.mobileCSS) {
      offsetStart = 'top 90%'
    }
    this.stParamsDesc = {
      trigger:this.DOM.desc,
      start:offsetStartDesc
    }

    for (const [i, fx] of this.FX.titleP.entries()) {
      let fn = false;
      if(i == this.FX.titleP.length -1) {
        fn = this.fnComplete
      }
      gsap.fromTo(
        fx,
        {
          y:this.FX.settings.y.from
        },
        {
          y:0,
          delay:this.FX.settings.delays[i],
          duration:this.FX.settings.duration,
          ease:this.FX.settings.ease,
          stagger:this.FX.settings.stagger,
          scrollTrigger:this.stParams,
          onComplete:()=>{
            if(fn != false && typeof fn == 'function') {
              for(let title of this.DOM.titleP) {
                title.classList.add('visible')
              }
              fn()
            }

          }
        }
      )
    }

    if(Env.mobileCSS == true) {
      gsap.fromTo(
        this.DOM.gallery,
        {
          autoAlpha:0,
        },
        {
          autoAlpha:1,
          ease:'cubic.out',
          duration:1,
          delay:1,
          stagger:.05,
          scrollTrigger:this.stParams
        }
      )
    }



    gsap.fromTo(
      this.DOM.desc,
      {
        autoAlpha:0,
        y:50
      },
      {
        autoAlpha:1,
        y:0,
        ease:'power3.out',
        duration:1,
        scrollTrigger:this.stParamsDesc
      }
    )
  }

  prepare()
  {
    for (const [i, title] of this.DOM.titleP.entries()) {
      const instance = new SplitText(title, { type:'chars'})
      let elToAnimate = instance.chars
      if(i%2 == 1) {
        elToAnimate = instance.chars.reverse()
      }
      this.FX.titleP.push(elToAnimate)
    }
  }

  setup()
  {
    this.prepare()
    this.animate()
  }
}
