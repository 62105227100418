import Env from '../../base/env'
import gsap from 'gsap'
import { ScrollTrigger } from "../../vendor/gsap-bonus-umd/ScrollTrigger";
import { SplitText } from "../../vendor/gsap-bonus-umd/SplitText";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

export default class FxHerozone
{
  constructor(view)
  {
    this.DOM =
    {
      view:view
    }
    this.DOM.words = this.DOM.view.querySelectorAll('.restaurant-title > .word')
    this.DOM.morphose = this.DOM.view.querySelectorAll('.morphose > .word')
    this.DOM.visual = this.DOM.view.querySelector('.jsVisual')
    this.DOM.visualImg = this.DOM.visual.querySelector('.jsVisualSrc')
    this.DOM.visualsGrid = this.DOM.view.querySelectorAll('.jsVisualDyptic')
    this.DOM.desc = this.DOM.view.querySelector('.jsDesc p')
    this.DOM.prose = this.DOM.view.querySelector('.jsProse')
    this.DOM.cols = this.DOM.view.querySelectorAll('.fxCol')
    //
    this.FX = {}
    // Fns
    this.setup()
  }

  animate()
  {
    const tl = gsap.timeline()

    tl.add(()=>{
      gsap.set(this.DOM.view.querySelector('.restaurant-title'),{ autoAlpha:1 })
    })
    tl.add([
      gsap.fromTo(
        this.FX.splitWords.chars,
        {
          y:'110%'
        },
        {
          y:0,
          ease:'quint.out',
          duration:1,
          stagger:.02
        }
      ),
      gsap.fromTo(
        this.DOM.morphose,
        {
          scale:0
        },
        {
          scale:1,
          ease:Env.eases.soap,
          duration:1,
          stagger:.05
        }
      ),
    ])
    tl.add([
      gsap.fromTo(
        this.DOM.visual,
        {
          autoAlpha:0,
          y:200
        },
        {
          autoAlpha:1,
          y:0,
          ease:'quint.inOut',
          duration:2
        }
      ),
      gsap.fromTo(
        this.DOM.visualImg,
        {
          y:25,
          scale:1.4
        },
        {
          scale:1.1,
          ease:'quint.inOut',
          duration:2
        }
      )
    ],'-=1.8')
    tl.add(()=>{
      const viewport = Env.framework.toolbox.getViewport()
      let offsetStart = (Env.mobileCSS)?'top 15%':'top 25%'
      const trigger = this.DOM.visualImg
      const scrub = 1
      // ScrollTrigger
      gsap.fromTo(
        this.DOM.visual,
        {
          y:0
        },
        {
          y:10,
          scrollTrigger:{
            trigger: trigger,
            start:offsetStart,
            end:`+=${viewport.height/1.5}`,
            scrub:scrub
          }
        }
      )
      gsap.fromTo(
        this.DOM.visualImg,
        {
          y:25,
        },
        {
          y:-25,
          scrollTrigger:{
            trigger: trigger,
            start:offsetStart,
            end:`+=${this.DOM.visualImg.getBoundingClientRect().height}px`,
            scrub:scrub
          }
        }
      )
      gsap.set(this.DOM.desc.parentElement , { autoAlpha: 1 })
      let startOffsetDesc = (Env.mobileCSS)?'top 90%':'top 70%';
      gsap.fromTo(
        this.FX.splitDesc.lines,
        {
          autoAlpha:0,
          y:25
        },
        {
          autoAlpha:1,
          y:0,
          stagger:.1,
          duration:1,
          ease:'quint.out',
          scrollTrigger:{
            trigger:this.DOM.desc,
            start:startOffsetDesc
          }
        }
      )
    })
    //! Dyptic
    for (const [i, visual] of this.DOM.visualsGrid.entries()) {
      let xPos = '-100%'
      if(i%2 == 1) {
        xPos = '100%'
      }
      let startTrigger = 'top 70%';
      if(Env.mobileCSS) {
        startTrigger = 'top 90%'
      }
      const tlVisual = gsap.timeline({
        scrollTrigger:{
          trigger:visual,
          start:startTrigger
        }
      })
      tlVisual.add([
        gsap.fromTo(
          visual.querySelector('.pattern'),
          {
            x:0
          },
          {
            x:xPos,
            ease:Env.eases.soap,
            duration:1.5
          }
        ),
        gsap.fromTo(
          visual.querySelector('.jsVisualSrc'),
          {
            scale:1.5
          },
          {
            scale:1,
            ease:'quint.out',
            duration:1.5,
            delay:.2
          }
        )
      ])
    }
    //! Prose
    gsap.fromTo(
      this.DOM.cols,
      {
        autoAlpha:0,
        y:25
      },
      {
        autoAlpha:1,
        y:0,
        ease:'quint.out',
        stagger:.25,
        duration:1.5,
        scrollTrigger:{
          trigger:this.DOM.cols[0],
          start:'top 70%'
        }
      }
    )
  }

  prepare()
  {
    this.FX.splitWords = new SplitText(this.DOM.words, { type:'chars' })
    this.FX.splitDesc = new SplitText(this.DOM.desc, { type:'lines' })

    // this.FX.prose = new SplitText(this.DOM.prose, { type:'lines' })
  }

  setup()
  {
    this.prepare()
    this.animate()
  }
}
