import gsap from 'gsap'

export default class Image {
  constructor(el) {
      this.DOM = {el: el};
      // image deafult styles
      this.defaultStyle = {
          scale: 1,
          x: 0,
          y: 0,
          opacity: 0
      };
      // get sizes/position
      this.getRect();
      // init/bind events
      this.initEvents();
  }
  initEvents() {
      // on resize get updated sizes/position
      window.addEventListener('resize', () => this.resize());
  }
  resize() {
      // reset styles
      gsap.set(this.DOM.el, this.defaultStyle);
      // get sizes/position
      this.getRect();
  }
  getRect() {
      this.rect = this.DOM.el.getBoundingClientRect();
  }
  isActive() {
      // check if image is animating or if it's visible
      return gsap.isTweening(this.DOM.el) || this.DOM.el.style.opacity != 0;
  }
}
