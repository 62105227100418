import Env from '../../base/env'
import gsap from 'gsap'
import { ScrollTrigger } from "../../vendor/gsap-bonus-umd/ScrollTrigger";
import { SplitText } from "../../vendor/gsap-bonus-umd/SplitText";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
import { animCircleBg,animDescCta } from '../Common'

export default class Hotel
{
  constructor(view,fnComplete)
  {
    //? DOM
    this.DOM = {}
    this.DOM.view = view
    this.DOM.fillBg = this.DOM.view.querySelector('.fill-bg')
    this.DOM.titleP = this.DOM.view.querySelectorAll('.home-restaurant-title span')
    this.DOM.subtitles = this.DOM.view.querySelectorAll('.tags span')
    this.DOM.desc = this.DOM.view.querySelector('.home-restaurant-desc')
    this.DOM.cta = this.DOM.view.querySelector('.home-restaurant-cta')
    this.DOM.visuals = this.DOM.view.querySelectorAll('.jsVisual')
    //? FX
    this.FX = {}
    this.FX.settings = {
      ease:'power3.out',
      duration:1,
      stagger:.1,
      y: {
        from:250
      },
      delays: [0,.4,.4,.8],
      visuals:{
        to:['-100%','100%','-100%','100%'],
        parallax:[120,-70,-200,-250]
      }
    }
    this.FX.titleP = []
    this.FX.subtitles = []
    //? Fns
    this.setup()
  }

  animate()
  {
    const viewport = Env.framework.toolbox.getViewport()
    //? Fill BG
     animCircleBg(this.DOM.fillBg,this.DOM.view)

    //? Title
    this.stParams = {
      trigger:this.DOM.view,
      start:'top 20%'
    }

    if(this.FX.titleP.length == 4) {

      const settingsFX = {
        duration:2,
        delay:.5
      }

      gsap.fromTo(
        this.DOM.titleP[1],
        {
          x:viewport.width / 3,
        },
        {
          x:0,
          duration:settingsFX.duration,
          ease:this.FX.settings.ease,
          scrollTrigger:this.stParams,
          delay:settingsFX.delay
        }
      )
      gsap.fromTo(
        this.DOM.titleP[2],
        {
          x:viewport.width / -3,
        },
        {
          x:0,
          duration:settingsFX.duration,
          ease:this.FX.settings.ease,
          scrollTrigger:this.stParams,
          delay:settingsFX.delay
        }
      )
    }

    for (const [i, fx] of this.FX.titleP.entries()) {
      gsap.fromTo(
        fx,
        {
          y:this.FX.settings.y.from,
        },
        {
          y:0,
          delay:this.FX.settings.delays[i],
          duration:this.FX.settings.duration,
          ease:this.FX.settings.ease,
          stagger:this.FX.settings.stagger,
          scrollTrigger:this.stParams,
          onComplete:()=>{
            if(i == this.FX.titleP.length - 1) {
              for(let title of this.DOM.titleP) {
                title.parentElement.classList.add('visible')
              }
            }
          }
        }
      )
    }

    //! Subtitle
    for (const [i, fx] of this.FX.subtitles.entries()) {
      gsap.fromTo(
        fx,
        {
          y:60
        },
        {
          y:0,
          delay:this.FX.settings.delays[i],
          duration:this.FX.settings.duration,
          ease:this.FX.settings.ease,
          stagger:this.FX.settings.stagger,
          scrollTrigger:this.stParams
        }
      )
    }

    //! Desc
    animDescCta(this.DOM.desc,this.DOM.cta)

    //! Visuals
    for (const [i, visual] of this.DOM.visuals.entries()) {
      let startOffsetVisual = (Env.mobileCSS)?'top 90%':'top 70%';
      const tl = gsap.timeline({
          scrollTrigger:{
          trigger:visual,
          start:startOffsetVisual,
        }
      })
      if(Env.mobileCSS) {
        tl.timeScale(1.5)
      }
      const goTo = this.FX.settings.visuals.to[i%this.FX.settings.visuals.to.length]
      const pattern = visual.querySelector('.pattern')
      tl.add([
        gsap.fromTo(
          pattern,
          {
            x:0
          },
          {
            x:goTo,
            ease:Env.eases.soap,
            duration:.6,
            onComplete:()=>{
              pattern.classList.add('tw-hidden')
            }
          }
        ),
        gsap.fromTo(
          visual.querySelector('.jsVisualSrc'),
          {
            scale:1.3
          },
          {
            scale:1.1,
            ease:'quint.out',
            duration:.6,
            delay:.2
          }
        )
      ])

      gsap.fromTo(
        visual.querySelector('.jsVisualSrc'),
        {
          y:50
        },
        {
          y:-50,
          ease:'cubic.out',
          scrollTrigger:{
            trigger:visual,
            start:'top bottom',
            end:'bottom top',
            scrub:1
          }
        }
      )

      if(Env.mobileCSS == false) {
        const setParallax = this.FX.settings.visuals.parallax[i%this.FX.settings.visuals.parallax.length]
        gsap.fromTo(
          visual,
          {
            y:0
          },
          {
            y:setParallax,
            ease:'cubic.out',
            scrollTrigger:{
              trigger:this.DOM.visuals[0],
              start:'top bottom',
              end:`+=${viewport.height * 4}`,
              scrub:1
            }
          }
        )
      }
    }


  }

  prepare()
  {
    for (const [i, title] of this.DOM.titleP.entries()) {
      const instance = new SplitText(title, { type:'chars'})
      let elToAnimate = instance.chars
      if(i%3 == 2) {
        elToAnimate = instance.chars.reverse()
      }
      this.FX.titleP.push(elToAnimate)
    }

    //? subtitles
    for(let subtitle of this.DOM.subtitles) {
      const instance = new SplitText(subtitle, { type:'chars'})
      this.FX.subtitles.push(instance.chars)
    }
  }

  setup()
  {
    this.prepare()
    this.animate()
  }
}
