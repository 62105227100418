import Env from '../base/env'
import gsap from 'gsap'
import { ScrollTrigger } from "../vendor/gsap-bonus-umd/ScrollTrigger";
import { SplitText } from "../vendor/gsap-bonus-umd/SplitText";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

export function animCircleBg(el,trigger)
{
  //? Fill BG
  gsap.fromTo(
    el,
    {
      y: 200,
    },
    {
      y:0,
      scrollTrigger: {
        trigger:trigger,
        start:'top bottom',
        end:'top 50%',
        scrub:Env.scrubTiming
      }
    }
  )
  gsap.fromTo(
    el,
    {
      borderRadius:'50%',
    },
    {
      borderRadius:'25%',
      scrollTrigger: {
        trigger:trigger,
        start:'top 25%',
        end:`top top+=${Env.uiHeight * 2}`,
        scrub:Env.scrubTiming
      }
    }
  )
}

export function animDescCta(desc,cta)
{
  //? Desc
  const tlDesc = gsap.timeline({
    scrollTrigger: {
      trigger:desc,
      start:'top 70%',
    }
  })
  const coordCta = cta.getBoundingClientRect()
  tlDesc.add([
    gsap.fromTo(
      desc,
      {
        autoAlpha:0,
        y:50
      },
      {
        autoAlpha:1,
        y:0,
        ease:'power3.out',
        duration:1,
      }
    ),
    gsap.fromTo(
      cta.querySelector('.shape'),
      {
        right:coordCta.width/2,
        left:coordCta.width/2,
        transformOrigin:'center center'
      },
      {
        right:0,
        left:0,
        delay:.1,
        ease:'power2.out',
        duration:.6,
      }
    ),
    gsap.fromTo(
      cta.querySelector('.text'),
      {
        autoAlpha:0,
        y:5,
      },
      {
        autoAlpha:1,
        y:0,
        delay:.4,
        ease:'power2.out',
        duration:.6,
      }
    )
  ])
}

export function FooterFx(view)
{
  const DOM = {}
  DOM.view = view
  DOM.footer = view.querySelector('.jsFooterView')
  if(DOM.footer == null) {
    return false
  }
  DOM.border = view.querySelector('.jsFooterBorder')
  DOM.links = DOM.footer.querySelector('.links')
  DOM.linksBottom = DOM.footer.querySelectorAll('.links-bottom')
  DOM.logo = DOM.footer.querySelector('.logo')
  DOM.separator = DOM.footer.querySelector('.separator')
  //? TL START
  let offsetStartTop = 'bottom bottom+=20%'
  let setTrigger = DOM.view
  if(Env.mobileCSS) {
    offsetStartTop = 'top bottom-=200'
    setTrigger = DOM.footer
  }
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: setTrigger,
      start:offsetStartTop,
      markers:false,
      onEnter:()=>{
        console.log('enter');
      }
    }
  })

  if(DOM.border == null) {
    DOM.border = { scaleX: 0 , transformOrigin: ''}
  }

  tl.add([
    gsap.fromTo(
      DOM.border,
      {
        scaleX:0,
        transformOrigin:'left top'
      },
      {
        scaleX:1,
        ease:'power3.out',
        duration:1
      }
    ),
    gsap.fromTo(
      DOM.links,
      {
        autoAlpha:0,
        y:40
      },
      {
        autoAlpha:1,
        y:0,
        ease:'cubic.out',
        duration:1,
        delay:.5
      }
    ),
  ])

  //? Logo anim
  const logoAnim = gsap.fromTo(
    DOM.logo.querySelectorAll('path'),
    {
      scale:0,
      transformOrigin:'center center'
    },
    {
      scale:1,
      ease:Env.eases.soap,
      duration:1,
      stagger:.05,
      scrollTrigger:{
        trigger:DOM.logo,
        start:'top 70%',
        markers:false
      }
    }
  )

  tl.add([
    gsap.fromTo(
      DOM.separator,
      {
        scaleX:0,
        transformOrigin:'left top'
      },
      {
        scaleX:1,
        ease:'power3.out',
        duration:1,

      }
    ),
    gsap.fromTo(
      DOM.linksBottom,
      {
        autoAlpha:0,
        y:40
      },
      {
        autoAlpha:1,
        y:0,
        stagger:.02,
        ease:'cubic.out',
        duration:1,
      }
    ),
  ],'-=.5')

  return { timeline:tl,logoAnim:logoAnim }
}

export function RefreshFooterTrigger()
{
  if(Env.uiFooterAPI != false){
    if(typeof Env.uiFooterAPI.timeline != 'undefined')
    {
      Env.uiFooterAPI.timeline.scrollTrigger.refresh()
    }
    if(typeof Env.uiFooterAPI.logoAnim != 'undefined')
    {
      Env.uiFooterAPI.logoAnim.scrollTrigger.refresh()
    }
  }
}


export function DestroyFooterTrigger()
{
  if(Env.uiFooterAPI != false){
    if(typeof Env.uiFooterAPI.timeline != 'undefined' && Env.uiFooterAPI.timeline.scrollTrigger != null)
    {
      Env.uiFooterAPI.timeline.scrollTrigger.kill(false)
    }
    if(typeof Env.uiFooterAPI.logoAnim != 'undefined' && Env.uiFooterAPI.logoAnim.scrollTrigger != null)
    {
      Env.uiFooterAPI.logoAnim.scrollTrigger.kill(false)
    }
  }
}

export function DescReveal(el,trigger)
{
  let offsetStart = 'top 70%'
  if(Env.mobileCSS) {
    offsetStart = 'top 90%'
  }
  gsap.fromTo(
    el,
    {
      autoAlpha:0,
      y:25
    },
    {
      autoAlpha:1,
      y:0,
      stagger:.1,
      duration:1,
      ease:'quint.out',
      scrollTrigger:{
        trigger:trigger,
        start:offsetStart
      }
    }
  )
}


export function CardRequests(view)
{
  const DOM = {
    view:view
  }
  DOM.titles = DOM.view.querySelectorAll('.jsTitle')
  DOM.descs = DOM.view.querySelectorAll('.jsDesc')
  DOM.cta = DOM.view.querySelectorAll('.jsCta')
  DOM.separator = DOM.view.querySelector('.jsSeparator')
  const FX = {}
  FX.titles = new SplitText(DOM.titles, { type: 'lines'})
  const tl = gsap.timeline({
    scrollTrigger:{
      trigger:view,
      start:'top 50%'
    }
  })

  tl.add([
    gsap.fromTo(
      DOM.separator,
      {
        transformOrigin:'left top',
        scaleY:0
      },
      {
        scaleY:1,
        ease:'quint.out',
        duration:1,
        duration:1
      }
    ),
    gsap.fromTo(
      FX.titles.lines,
      {
        autoAlpha:0,
        y:50
      },
      {
        autoAlpha:1,
        y:0,
        duration:1,
        stagger:.2,
        ease:'quint.out'
      }
    ),
    gsap.fromTo(
      DOM.descs,
      {
        autoAlpha:0,
        y:50
      },
      {
        autoAlpha:1,
        y:0,
        duration:1,
        stagger:.2,
        delay:.4,
        ease:'quint.out'
      }
    ),
    gsap.fromTo(
      DOM.cta,
      {
        autoAlpha:0,
        y:30
      },
      {
        autoAlpha:1,
        y:0,
        duration:1,
        stagger:.2,
        delay:.6,
        ease:'quint.out'
      }
    )
  ])
}


export function ShopItemsReveal(items)
{
  const viewport = Env.framework.toolbox.getViewport()
  let iFx = 0
  for (const [i, item] of items.entries()) {
    // console.log();
    let startOffset = (Env.mobileCSS)?'top 150%':`top 150%`;
    let durationFx = (Env.mobileCSS)?.8:1;
    // let delayFx = (Env.mobileCSS)?0:i*0.02;
    // console.log(object);
    let delayFx = 0

    if(Env.mobileCSS) {
      if(iFx == 2) {
        iFx = 0
      }
    } else {
      if(iFx == 4) {
        iFx = 0
      }
    }

    if(item.classList.contains('jsMobileView') == false) {
      iFx++
      delayFx = (Env.mobileCSS)?0:iFx/10;
    }

    let yPosStart = (Env.mobileCSS)?'150%':viewport.height / 2;
    gsap.fromTo(
      item,
      {
        y:yPosStart
      },
      {
        y:0,
        ease:Env.eases.soap,
        duration:durationFx,
        delay:delayFx,
        onStart:()=>{
          setTimeout(() => {
            const pattern = item.querySelector('.pattern')
            if(pattern != null) {
              gsap.fromTo(
                item.querySelector('.pattern'),
                {
                  x:'0'
                },
                {
                  x:'-100%',
                  ease:Env.eases.soap,
                  duration:1,
                  onComplete:()=>{
                    pattern.classList.add('tw-hidden')
                  }
                }
              )
            }
          }, 500);
        },
        scrollTrigger:{
          trigger:item,
          start:startOffset,
          markers:false
        }
      }
    )
  }
}

export function AnimLetterIn(data)
{
  for (const [w, letter] of data.entries()) {
    if(w%2 == 0) {
      const randomY = Env.framework.toolbox.getRandomInt(2,10)
      const randomRotate = Env.framework.toolbox.getRandomInt(5,20)
      gsap.to(
        letter,
        {
          y:randomY,
          rotate:randomRotate,
          ease:'quint.out',
          duration:.4
        }
      )
    } else {
      const randomY = Env.framework.toolbox.getRandomInt(-2,-8)
      const randomRotate = Env.framework.toolbox.getRandomInt(-5,-20)
      gsap.to(
        letter,
        {
          y:randomY,
          rotate:randomRotate,
          ease:'quint.out',
          duration:.4
        }
      )
    }
  }
}

export function AnimLetterOut(data)
{
  gsap.to(
    data,
    {
      y:0,
      rotate:0,
      ease:'quint.out',
      duration:.4
    }
  )
}


export function VisualParallax(visual,visualImg,increment)
{
  const scrub = 1
  // Parallax Visual
  let yParallaxTo
  if(increment%2 == 1) {
    yParallaxTo=50
  } else {
    yParallaxTo=-50
  }

  gsap.fromTo(
    visual,
    {
      y:0
    },
    {
      y:yParallaxTo,
      scrollTrigger:{
        trigger: visual,
        start:'top bottom',
        end:`bottom top`,
        scrub:scrub
      }
    }
  )

  gsap.fromTo(
    visualImg,
    {
      y:25,
    },
    {
      y:-25,
      scrollTrigger:{
        trigger: visual,
        start:'top bottom',
        end:`bottom top`,
        scrub:scrub
      }
    }
  )
}

export function hoverMainButtons()
{
  const DOM = {
    buttons:document.querySelectorAll('.jsUiBtn'),
  }

  for (const [i, btn] of DOM.buttons.entries()) {

    let captureColor;
    const shape = btn.querySelector('.shape-concave')
    const txt = btn.querySelector('.txt-cta')
    const lines = btn.querySelectorAll('.open .line')
    btn.addEventListener('mouseenter',()=>{

      let colorHover = '#B78959'
      captureColor = getComputedStyle(txt).getPropertyValue('color');
      if(captureColor == 'rgb(237, 28, 50)') {
        colorHover = '#fff'
      }
      const tlIn = gsap.timeline()
      tlIn.add([
        gsap.fromTo(
          btn,
          {
            scale:1
          },
          {
            scale:1.08,
            ease:Env.eases.soap,
            duration:.7
          }
        ),
        gsap.fromTo(
          shape,
          {
            scaleX:0
          },
          {
            scaleX:1.15,
            ease:Env.eases.soap,
            duration:.8
          }
        ),
        gsap.fromTo(
          txt,
          {
            color:colorHover,
            autoAlpha:1,
            y:0
          },
          {
            autoAlpha:0,
            y:'-100%',
            ease:'quint.inOut',
            duration:.5
          }
        )
      ])
      if(lines.length) {
        tlIn.add([
          gsap.fromTo(
            lines,
            {
              scaleX:1,
              transformOrigin:'right top'
            },
            {
              scaleX:0,
              ease:Env.eases.soap,
              duration:.4,
              stagger:.05
            }
          )
        ],'>-=.5')
      }
      tlIn.add([
        gsap.fromTo(
          txt,
          {
            autoAlpha:0,
            y:'110%'
          },
          {
            y:0,
            autoAlpha:1,
            ease:'quint.out',
            duration:.5
          }
        )
      ],'-=.25')
      if(lines.length) {
        tlIn.add([
          gsap.fromTo(
            lines,
            {
              backgroundColor:colorHover,
              transformOrigin:'left top'
            },
            {
              immediateRender:false,
              scaleX:1,
              ease:Env.eases.soap,
              duration:.4,
              stagger:.05
            }
          )
        ],'-=.4')
      }
    })
    btn.addEventListener('mouseleave',()=>{
      const tlOut = gsap.timeline()
      let setColor = '#fff';
      setColor = captureColor
      gsap.killTweensOf(btn);
      gsap.killTweensOf(shape);
      gsap.killTweensOf(txt);
      if(lines.length) {
        gsap.killTweensOf(lines);
      }
      tlOut.add([
        gsap.to(
          btn,
          {
            scale:1,
            ease:Env.eases.soap,
            duration:.7
          }
        ),
        gsap.to(
          shape,
          {
            scaleX:0,
            ease:Env.eases.soap,
            duration:.5
          }
        ),
        gsap.fromTo(
          txt,
          {
            autoAlpha:1,
            y:0
          },
          {
            autoAlpha:0,
            y:0,
            ease:'cubic.out',
            duration:.5
          }
        )
      ])
      if(lines.length) {
        tlOut.add([
          gsap.fromTo(
            lines,
            {
              scaleX:1,
              transformOrigin:'right top'
            },
            {
              scaleX:0,
              ease:Env.eases.soap,
              duration:.4,
              stagger:.05
            }
          )
        ],'>-=.5')
      }
      tlOut.add([
        gsap.fromTo(
          txt,
          {
            color:setColor,
            autoAlpha:1,
            y:'120%'
          },
          {
            autoAlpha:1,
            y:0,
            ease:Env.eases.soap,
            duration:.4
          }
        )
      ],'-=.5')
      if(lines.length) {
        tlOut.add([
          gsap.fromTo(
            lines,
            {
              backgroundColor:setColor,
              transformOrigin:'left top'
            },
            {
              immediateRender:false,
              scaleX:1,
              ease:Env.eases.soap,
              duration:.4,
              stagger:.05
            }
          )
        ],'-=.4')
      }
      tlOut.add(()=>{
        gsap.set(lines,{ clearProps:'backgroundColor,scaleX' })
        gsap.set(txt,{ clearProps:'all' })
        gsap.set(shape,{ clearProps:'scaleX' })
        gsap.set(btn,{ clearProps:'scale' })

      })
    })
  }
}
