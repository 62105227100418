import gsap from 'gsap'
import { ScrollTrigger } from "../../vendor/gsap-bonus-umd/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class Logo
{
  constructor(view)
  {
    this.DOM = {}
    this.DOM.view = view
    this.DOM.paths = this.DOM.view.querySelectorAll('path')
    this.animate()
  }

  animate()
  {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger:this.DOM.view,
        start:'bottom 80%',
        end:'bottom top',
        markers: false,
        scrub:true
      }
    })

    tl.add([
      gsap.fromTo(
        this.DOM.paths[0],
        {
          transformOrigin: 'top left'
        },
        {
          rotate:-15,
          y:-50
        }
      ),
      gsap.fromTo(
        this.DOM.paths[1],
        {
          transformOrigin: 'top left'
        },
        {
          rotate:-35,
          y:-70
        }
      ),
      gsap.fromTo(
        this.DOM.paths[2],
        {
          transformOrigin: 'top left'
        },
        {
          rotate:-10,
          y:-90
        }
      ),
      gsap.fromTo(
        this.DOM.paths[3],
        {
          transformOrigin: 'top left'
        },
        {
          rotate:10,
          y:-110
        }
      ),
      gsap.fromTo(
        this.DOM.paths[4],
        {
          transformOrigin: 'top left'
        },
        {
          rotate:40,
          y:-120
        }
      )
    ])
  }
}
