import Env from '../../base/env'
import gsap from 'gsap'
import { SplitText } from "../../vendor/gsap-bonus-umd/SplitText";
gsap.registerPlugin(SplitText);
import { DescReveal } from '../Common'

export default class FxProse
{
  constructor(view)
  {
    this.DOM = {
      view: view,
      desc: view.querySelector('.fxProse'),
      title:view.querySelector('.fxRoomTitle')
    }
    this.FX = {}

    this.setup()
  }

  animate()
  {
    let offsetStart = 'top 70%'
    if(Env.mobileCSS) {
      offsetStart = 'top 90%'
    }
    gsap.fromTo(
      this.DOM.title,
      {
        autoAlpha:0,
        y:20,
      },
      {
        autoAlpha:1,
        y:0,
        ease:'quint.out',
        duration:1,
        scrollTrigger:{
          trigger:this.DOM.title,
          start:offsetStart
        }
      }
    )
    DescReveal(this.FX.split.lines,this.DOM.desc)
  }

  prepare()
  {
    this.FX.split = new SplitText(this.DOM.desc.querySelectorAll('p'), { type:'lines' })
  }

  setup()
  {
    this.prepare()
    this.animate()
  }
}
