'use strict';
import Barba from 'barba.js';
import Env from '../base/env.js';
import Channels from '../base/channels'
import Client from 'shopify-buy';
import Trail from '../fx/ImageTrail/Trail'
import HoverMarquee from '../fx/HoverMarquee.js';
import Splide from '@splidejs/splide';
import LoopVisuals from '../fx/LoopVisuals'
import FxLogo from '../fx/Home/Logo.js';
import FxIntroduction from '../fx/Home/Introduction';
import FxHotel from '../fx/Home/Hotel';
import FxRestaurant from '../fx/Home/Restaurant';
import FxShop from '../fx/Home/Shop';
import FxHerozone from '../fx/Home/Herozone';
import { renderItem } from '../components/Helpers'
import gsap from 'gsap'
import { Observer} from '../vendor/gsap-bonus-umd/Observer'
gsap.registerPlugin(Observer);


let page

class Page
{
  constructor(view='')
  {
      this.view = view
      this.classView = 'currentView--home'
      this.DOM = {}
      this.DOM.view = view
      // Shopify
      this.shopifyClient = Client.buildClient({
        domain: Env.shopifyDomain,
        storefrontAccessToken: Env.storefrontAccess
      });
      // Launch Fn
      this.funcLaunch = this.intro.bind(this)
      if(Env.framework.UI.launcher.LAUNCHED == false) this.channels()
  }

  channels()
  {
      Channels.on('launcher::exit',this.funcLaunch)
  }

  destroy()
  {
      Channels.removeListener('launcher::exit', this.funcLaunch);
      this.trail.disable()
  }

  finalize()
  {
      setTimeout(() => {
        if(Env.isVisiting === true) {
          setTimeout(() => {
            this.intro()
          }, Env.timingIntro);
        }
        this.fx()
        this.slideshowMobileTrail()
      }, 200);
  }

  fx()
  {
    this.trail = new Trail()

    new HoverMarquee(this.DOM.view.querySelectorAll('.home-shop .jsItem'))
    new LoopVisuals(this.DOM.view.querySelector('.jsLoop'))
    new FxLogo(this.DOM.view.querySelector('.jsFxLogo'))
    new FxIntroduction(this.DOM.view.querySelector('.jsIntroductionView'),()=>{
      this.trail.setup(this.DOM.view.querySelector('.jsIntroductionView'))
    })
    new FxHotel(this.DOM.view.querySelector('.jsHotelView'))
    new FxRestaurant(this.DOM.view.querySelector('.jsRestaurantView'))
    new FxShop(this.DOM.view.querySelector('.jsShopView'))

    this.loadShopifyDatas(this.DOM.view.querySelectorAll('.jsShopView .jsItem'))
  }

  loadShopifyDatas(items)
  {
    const itemsMobile = this.DOM.view.querySelectorAll('.jsShopView .jsItemMobile')
    const totalItems = items.length - 1
    for (const [i, item] of items.entries()) {
      const itemMobile = itemsMobile[i]
      // Fetch a single product by Handle
      const handle = item.getAttribute('data-handle');
      this.shopifyClient.product.fetchByHandle(handle).then((product) => {
        renderItem(item,product)
        // Render for mobile
        const imgProduct = new Image()
        imgProduct.classList.add('tw-w-full')
        imgProduct.classList.add('tw-h-full')
        imgProduct.classList.add('tw-object-cover')
        imgProduct.src = product.images[0].src
        itemMobile.querySelector('a').appendChild(imgProduct)
        if(totalItems == i) {
          this.slideshow()
        }
      });
    }
  }

  slideshow()
  {

    let settings = {
      perPage: 1,
      gap:0,
      padding: { left: 0, right: 100 },
    }

    this.slideshowShop= new Splide(this.DOM.view.querySelector('.jsSlideshowShop') , {
      arrows: false,
      perPage:settings.perPage,
      perMove:1,
      gap:settings.gap,
      padding:settings.padding,
      type: 'loop',
      autoplay: false,
      interval: 1000,
      rewind: true,
      pauseOnFocus:false,
      pauseOnHover:false,
      pagination:true,
      speed: 700,
    }).mount();
  }

  slideshowMobileTrail()
  {
    const self = this
    const rotateRatio = 10
    const zRatio = -20
    const DOM = {}
    let interval = false
    DOM.view = this.DOM.view.querySelector('.home-introduction-gallery')
    DOM.items = DOM.view.querySelectorAll('li')
    const TOTAL = DOM.items.length - 1
    let zIndexMove = 0

    refreshZindex(zIndexMove)
    autoplay()

    this.DOM.view.querySelector('.home-introduction-gallery').addEventListener('click',()=>{
      zIndexMove++
      if(zIndexMove > TOTAL) {
        zIndexMove = 0
      }
      clearInterval(interval)
      refreshZindex(zIndexMove)
      autoplay()
    })

    function autoplay()
    {
      interval = setInterval(() => {
        self.DOM.view.querySelector('.home-introduction-gallery').click()
      }, 1000);
    }




    function refreshZindex(active)
    {
      for (const [i, el] of DOM.items.entries()) {
        el.classList.remove('is-active')
      }
      gsap.set(DOM.items[active],{ zIndex: TOTAL })
      gsap.set(DOM.items[active].querySelector('.visual-context'),{ rotateZ: 0 , z:0 })
      DOM.items[active].classList.add('is-active')
      let rotateIncrement = 0
      let zIncrement = 0
      for (const [i, el] of DOM.items.entries()) {
        const getImg = el.querySelector('.visual-context')
        if(i > active) {
          const distance = i - active
          const setZindex = TOTAL - distance
          gsap.set(el,{ zIndex: setZindex })
          gsap.set(getImg,{ rotateZ: distance * rotateRatio , z : distance * zRatio })
          rotateIncrement += rotateRatio
          zIncrement += zRatio

        }
      }
      let restart = 0
      let max = active
      rotateIncrement += rotateRatio
      zIncrement += zRatio
      for (const [i, el] of DOM.items.entries()) {
        const getImg = el.querySelector('.visual-context')
        const setNewZ = max - restart
        if(i < active) {
          gsap.set(el,{ zIndex: setNewZ - 1 })
          gsap.set(getImg,{ rotateZ: rotateIncrement , z : zIncrement })
          restart++
          rotateIncrement += rotateRatio
          zIncrement += zRatio
        }
      }
    }
  }

  intro()
  {
    new FxHerozone(this.DOM.view)
  }
}

page = Barba.BaseView.extend({
    namespace: 'home',
    node: undefined,
    onEnter: function() {
        this.node = new Page(this.container)
        Env.$html.classList.add(this.node.classView)
    },
    onEnterCompleted:function()
    {
        this.node.finalize()
    },
    onLeave: function()
    {
        this.node.destroy()
    },
    onLeaveCompleted: function()
    {
        if(this.container.getAttribute('data-namespace') != this.namespace)
        {
            Env.$html.classList.remove(this.node.classView)
        }
    }
});

export default page;
