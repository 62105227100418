import Env from '../../base/env'
import gsap from 'gsap'
import { SplitText } from "../../vendor/gsap-bonus-umd/SplitText";
gsap.registerPlugin(SplitText);

export default class Journey
{
  constructor(view)
  {
    this.DOM = {
      view:view
    }
    this.DOM.title = this.DOM.view.querySelector('.jsTitle')
    this.DOM.cta = this.DOM.view.querySelector('.jsCta')
    //
    this.FX = {}
    //
    this.setup()
  }

  animate()
  {

    gsap.fromTo(
      this.FX.split.chars,
      {
        y:'150%'
      },
      {
        y:0,
        ease:Env.eases.soap,
        duration:1.5,
        stagger:.02,
        scrollTrigger:{
          trigger:this.DOM.title,
          start:'top 70%'
        }
      }
    )
    gsap.fromTo(
      this.DOM.cta,
      {
        autoAlpha:0,
        y:25
      },
      {
        autoAlpha:1,
        y:0,
        ease:'quint.out',
        duration:1.5,
        scrollTrigger:{
          trigger:this.DOM.cta,
          start:'top 80%'
        }
      }
    )
  }

  prepare()
  {
    this.FX.split = new SplitText(this.DOM.title, { type:'chars' })
  }

  setup()
  {
    this.prepare()
    this.animate()
  }
}
