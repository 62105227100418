import gsap from 'gsap'
import hoverintent from 'hoverintent'
import { SlowMo } from '../vendor/gsap-bonus-umd/EasePack'
gsap.registerPlugin(SlowMo);

export default class HoverMarquee
{
  constructor(items)
  {
    for(let $item of items) {
      let DOM = {}
      DOM.item = $item
      DOM.cache = $item.querySelector('.fxCache')
      DOM.price = $item.querySelector('.fxPrice')
      DOM.text = $item.querySelector('.fxTxt')
      DOM.text.style.willChange = 'transform'

      const coordItem = DOM.item.getBoundingClientRect()
      gsap.set(DOM.text, { x: coordItem.width+50 })

      hoverintent(DOM.item,
        function() {
          const coordItem = DOM.item.getBoundingClientRect()
          const coordTxt = DOM.text.getBoundingClientRect()
          gsap.fromTo(
            DOM.cache,
            {
              y:'-100%',
              autoAlpha:1
            },
            {
              y:0,
              duration:.5,
              ease:'power2.inOut',
              overwrite:false
            }
          )

          gsap.fromTo(
            DOM.price,
            {
              autoAlpha:0
            },
            {
              autoAlpha:1,
              duration:.5,
              delay:.5,
              ease:'cubic.out',
              overwrite:false
            }
          )

          gsap.fromTo(
            DOM.text,
            {
              x:coordItem.width+50
            },
            {
              x:(coordTxt.width + 50) * -1,
              duration:4,
              ease:"slow(0.7, 0.7, false)",
              repeat:-1
            }
          )
        },
        function() {
          gsap.killTweensOf(DOM.text)
          const coordTxt = DOM.text.getBoundingClientRect()
          gsap.to(
            DOM.text,
            {
              x:(coordTxt.width + 50) * -1,
              duration:.4,
              ease:'cubic.inOut',
            }
          )
          gsap.to(
            DOM.price,
            {
              autoAlpha:0,
              duration:.5,
              ease:'cubic.out',
              overwrite:false
            }
          )
          gsap.to(
            DOM.cache,
            {
              y:'100%',
              duration:.8,
              ease:'power2.inOut',
              overwrite:false
            }
          )
        }
      ).options({
        timeout:500,
        interval:100
      });
    }
  }
}
