
import Env from '../base/env'
import Channels from '../base/channels'

class Launcher
{
    constructor()
    {
        if(Env.$launcher == null) return false
        // Dom
        this.view = Env.$launcher
        // Vars
        this.viewport = Env.framework.toolbox.getViewport()
        // Flags
        this.NO_INTRO = true
        this.LAUNCHED = false
        this.SPEED = 5
        if(Env.mobileCSS == true) {
            this.NO_INTRO = true
        }
        // FNs
        this.setup()
    }


    completeIn()
    {
        setTimeout(() => {
            this.out()
        }, 600);
    }

    completeOut()
    {
        Env.$html.classList.add('ui--isReady')
        this.LAUNCHED = true
        Env.$master.removeChild(this.view)
    }

    destroy()
    {

    }

    noLaunch()
    {
        Env.$master.removeChild(this.view)
        const controller = Env.framework.toolbox.getController()
        const timeoutTime = (Env.mobileCSS == true)?100:100;
        setTimeout(() => {
            Env.$html.classList.add('ui--isReady')
            setTimeout(() => {
              Channels.emit('launcher::exit')
            }, 200);
            this.LAUNCHED = true
        }, timeoutTime);
        setTimeout(() => {
            Env.$html.classList.add('ui--isFinalize')
        }, 2000);
    }

    out()
    {

        const getCurrentController = Env.framework.toolbox.getController()
    }

    setup()
    {
        Env.framework.toolbox.resetScroll()
        if(this.NO_INTRO) {
            this.noLaunch()
        } else {
            setTimeout(() => {
                this.out()
            }, 200);
        }
    }

}

export default Launcher;
