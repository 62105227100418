import Env from '../base/env'
import gsap from 'gsap'
import { SlowMo } from '../vendor/gsap-bonus-umd/EasePack'
gsap.registerPlugin(SlowMo);

export default class LoopVisuals {

  constructor(view)
  {
    this.DOM = {}
    this.DOM.view = view
    this.DOM.loopItems = this.DOM.view.querySelectorAll('.jsItem')
    this.DOM.loopItemsAll = this.DOM.view.querySelectorAll('.jsItemAll')
    this.DOM.loopMove = this.DOM.view.querySelector('.jsMove')
    this.DOM.getLast = this.DOM.loopItems[this.DOM.loopItems.length - 1]
    this.DOM.getLastAll = this.DOM.loopItemsAll[this.DOM.loopItemsAll.length - 1]
    const coord = this.DOM.getLast.getBoundingClientRect()
    const coordAll = this.DOM.getLastAll.getBoundingClientRect()
    const viewport = Env.framework.toolbox.getViewport()
    this.DOM.loopMove.style.willChange = 'transform'

    const moveWithScrollTrigger = ((coordAll.width + coordAll.x) - viewport.width) * -1

    gsap.fromTo(
      this.DOM.loopMove,
      {
        x:0
      },
      {
        x:(coord.width + coord.x) * -1,
        ease:"linear",
        duration:20,
        repeat:-1,
        // x:moveWithScrollTrigger,
        // ease:'power3.out',
        // scrollTrigger:{
        //   trigger:this.DOM.view,
        //   start:'top bottom-=5%',
        //   end:'+=4000',
        //   scrub:.7
        // }
      }
    )
  }
}
