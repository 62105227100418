import * as Utils from '../../vendor/utils'
import Image from './Image'
import gsap from 'gsap'
import Env from '../../base/env'

export default class Trail
{
  constructor()
  {

    this.mousePos = this.lastMousePos = this.cacheMousePos = {x: 0, y: 0};
    this.ENABLED = false
    this.zIndexIncrement = 0

    this.DOM = {
      content: document.querySelector('.jsTrail')
    };
    this.images = [];
    [...this.DOM.content.querySelectorAll('img')].forEach(img => this.images.push(new Image(img)));
    // Pattern
    this.patterns = [];
    [...this.DOM.content.querySelectorAll('.pattern')].forEach(p => this.patterns.push(p));
    // items
    this.items = [];
    [...this.DOM.content.querySelectorAll('.item')].forEach(p => this.items.push(p));
    // total number of images
    this.imagesTotal = this.images.length;
    // upcoming image index
    this.imgPosition = 0;
    // zIndex value to apply to the upcoming image
    this.zIndexVal = 1;
    // mouse distance required to show the next image
    this.threshold = 100;

    requestAnimationFrame(() => this.render());

  }

  events()
  {
    window.addEventListener('mousemove', ev => this.mousePos = Utils.getMousePos(ev));

    this.DOM.content.addEventListener('mouseenter',()=>{
      this.enable()
    })

    this.DOM.content.addEventListener('mouseleave',()=>{
      this.disable()
    })
  }

  disable()
  {
    this.ENABLED = false
  }

  enable()
  {
    if(Env.mobileCSS == false) {
      this.ENABLED = true
    }

  }

  getMouseDistance()
  {
    return Utils.distance(this.mousePos.x,this.mousePos.y,this.lastMousePos.x,this.lastMousePos.y);
  }

  render() {

    if(this.ENABLED)
    {
       // get distance between the current mouse position and the position of the previous image
       let distance = this.getMouseDistance();
       // cache previous mouse position
       this.cacheMousePos.x = Utils.lerp(this.cacheMousePos.x || this.mousePos.x, this.mousePos.x, 0.1);
       this.cacheMousePos.y = Utils.lerp(this.cacheMousePos.y || this.mousePos.y, this.mousePos.y, 0.1);

       // if the mouse moved more than [this.threshold] then show the next image
       if ( distance > this.threshold ) {
           this.showNextImage();

           ++this.zIndexVal;
           this.imgPosition = this.imgPosition < this.imagesTotal-1 ? this.imgPosition+1 : 0;

           this.lastMousePos = this.mousePos;
       }

       // check when mousemove stops and all images are inactive (not visible and not animating)
       let isIdle = true;
       for (let img of this.images) {
           if ( img.isActive() ) {
               isIdle = false;
               break;
           }
       }
       // reset z-index initial value
       if ( isIdle && this.zIndexVal !== 1 ) {
           this.zIndexVal = 1;
       }

    }
    // loop..
    requestAnimationFrame(() => this.render());
  }


  on()
  {
    this.ready()
  }

  ready()
  {
    this.DOM.content.classList.add('loaded')
  }

  setup()
  {
    this.events()
    this.on()
  }

  showNextImage() {
    // show image at position [this.imgPosition]
      const img = this.images[this.imgPosition];
      const elToAnimate = this.items[this.imgPosition];
      const elPattern = this.patterns[this.imgPosition];
      // kill any tween on the image
      gsap.killTweensOf(elToAnimate);
      gsap.killTweensOf(elPattern);

      const viewport = Env.framework.toolbox.getViewport()

      let setAngle = Math.atan2(this.mousePos.x - (viewport.width/2), - (this.mousePos.y - (viewport.height/1)) )*(180 / Math.PI);
      setAngle = setAngle/10

      elToAnimate.style.height = `${img.DOM.el.getBoundingClientRect().height}px`

      gsap.timeline()
      // show the image

      .set(elToAnimate, {
          startAt: {opacity: 0, scale: 1},
          opacity: 1,
          scale: 1,
          zIndex: this.zIndexIncrement,
          x: this.cacheMousePos.x - img.rect.width/2,
          y: this.cacheMousePos.y - img.rect.height/2,
          rotate:setAngle
      }, 0)
      .set(
        elPattern,
        {
          x:'0%'
        }
      )
      // animate position
      .to(elToAnimate, 0.9, {
          ease: 'expo.out',
          x: this.mousePos.x - img.rect.width/2,
          y: this.mousePos.y - img.rect.height/2
      }, 0)
      .to(elPattern, 0.5, {
        ease: Env.eases.soap,
        x: '-100%',
        delay:.2
      }, 0)
      // then make it disappear
      .to(elToAnimate, 1, {
          ease: 'power1.out',
          opacity: 0,
      }, 1.5)
      // // scale down the image
      // .to(img.DOM.el, 1, {
      //     ease: 'power2.out',
      //     scale: 0
      // }, 0.2);


      this.zIndexIncrement++
  }

}
