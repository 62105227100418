"use strict";
import Barba from "barba.js";
import Env from "../base/env.js";
import Channels from "../base/channels";
import Splide from "@splidejs/splide";
import gsap from "gsap";
import { ScrollTrigger } from "../vendor/gsap-bonus-umd/ScrollTrigger";
import { SplitText } from "../vendor/gsap-bonus-umd/SplitText";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
import FxGallery from "../fx/Hotel/Gallery";
import FxRooms from "../fx/Hotel/Rooms";
import FxProse from "../fx/Hotel/Prose";
import FxCardJourney from "../fx/Hotel/Journey";
import { CardRequests } from "../fx/Common";

let page;

class Page {
  constructor(view = "") {
    this.view = view;
    this.DOM = {};
    this.DOM.view = this.view;
    this.DOM.splide = this.DOM.view.querySelector(".splide");
    this.DOM.splideRooms = this.DOM.view.querySelectorAll(".jsRoomSlide");
    this.classView = "currentView--hotel";
    //
    this.FX = {
      gallerys: [],
    };
    // Launch Fn
    this.funcLaunch = this.intro.bind(this);
    if (Env.framework.UI.launcher.LAUNCHED == false) this.channels();
  }

  channels() {
    Channels.on("launcher::exit", this.funcLaunch);
  }

  destroy() {
    Channels.removeListener("launcher::exit", this.funcLaunch);
  }

  finalize() {
    setTimeout(() => {
      if (Env.isVisiting === true) {
        setTimeout(() => {
          this.intro();
        }, Env.timingIntro);
      }
      this.fx();
    }, 200);
  }

  fx() {
    this.slideshowByRoom();
    setTimeout(() => {
      for (let gallery of this.DOM.view.querySelectorAll(".jsFxGallery")) {
        const instance = new FxGallery(gallery);
        this.FX.gallerys.push(instance);
      }
      this.rooms = new FxRooms(this.DOM.view.querySelector(".FxRooms"));
      //! Prose
      this.prose = new FxProse(this.DOM.view);
      //! Journey
      this.journey = new FxCardJourney(
        this.DOM.view.querySelector(".fxCardJourney")
      );
      //! CardRequests
      CardRequests(this.DOM.view.querySelector(".jsCardRequest"));
    }, 200);
  }

  intro() {
    const viewport = Env.framework.toolbox.getViewport();
    const DOM = {};
    DOM.herozone = this.DOM.view.querySelector(".jsHerozoneView");
    DOM.title = DOM.herozone.querySelector(".jsTitle");
    DOM.visual = DOM.herozone.querySelector(".jsVisual");
    DOM.desc = DOM.herozone.querySelector(".jsDesc");
    DOM.visualImg = DOM.visual.querySelector("img");
    const splitTitleParent = new SplitText(DOM.title, {
      type: "chars",
      charsClass: "tw-overflow-hidden",
    });
    const splitTitleChild = new SplitText(DOM.title, { type: "chars" });
    // const splitDesc = new SplitText(DOM.desc.querySelector('p'), { type:'lines' })
    const tl = gsap.timeline();

    tl.add(() => {
      gsap.set(DOM.title, { autoAlpha: 1 });
    });
    tl.add([
      gsap.fromTo(
        splitTitleChild.chars,
        {
          y: "105%",
        },
        {
          y: "0%",
          duration: 1.5,
          stagger: 0.05,
          ease: "quint.out",
        }
      ),
      gsap.fromTo(
        DOM.visual,
        {
          autoAlpha: 0,
          y: 200,
        },
        {
          autoAlpha: 1,
          y: 0,
          ease: "quint.out",
          duration: 1.6,
        }
      ),
      gsap.fromTo(
        DOM.visualImg,
        {
          y: 25,
          scale: 1.4,
        },
        {
          scale: 1.25,
          ease: "quint.out",
          duration: 1.6,
        }
      ),
    ]);
    tl.add(() => {
      let offsetStart = Env.mobileCSS ? "top 15%" : "top 35%";
      const trigger = DOM.visualImg;
      const scrub = 1;
      // ScrollTrigger
      gsap.fromTo(
        DOM.visual,
        {
          y: 0,
        },
        {
          y: 10,
          scrollTrigger: {
            trigger: trigger,
            start: offsetStart,
            end: `+=${viewport.height / 1.5}`,
            scrub: scrub,
          },
        }
      );
      gsap.fromTo(
        DOM.visualImg,
        {
          y: 25,
        },
        {
          y: -25,
          scrollTrigger: {
            trigger: trigger,
            start: offsetStart,
            end: `+=${DOM.visualImg.getBoundingClientRect().height}px`,
            scrub: scrub,
          },
        }
      );
    });

    // Desc
    let startOffsetDesc = "top 70%";
    if (Env.mobileCSS) {
      startOffsetDesc = "top 90%";
    }
    gsap.set(DOM.desc, { autoAlpha: 1 });
    gsap.fromTo(
      DOM.desc.querySelectorAll("p"),
      {
        autoAlpha: 0,
        y: 50,
      },
      {
        autoAlpha: 1,
        y: 0,
        duration: 1,
        stagger: 0.05,
        ease: "quint.out",
        scrollTrigger: {
          trigger: DOM.desc,
          start: startOffsetDesc,
        },
      }
    );

    // Cta
    gsap.fromTo(
      DOM.herozone.querySelectorAll(".fxCtaHerozone"),
      {
        autoAlpha: 0,
        y: 50,
      },
      {
        autoAlpha: 1,
        y: 0,
        duration: 1,
        stagger: 0.05,
        ease: "quint.out",
        scrollTrigger: {
          trigger: DOM.desc,
          start: "top 30%",
        },
      }
    );
  }

  slideshow() {
    this.DOM.roomsListHeader =
      this.DOM.splide.parentElement.querySelector(".jsHeader");
    const coord = this.DOM.roomsListHeader.getBoundingClientRect();
    let settings = {
      perPage: 3,
      gap: 32,
      padding: { left: coord.x, right: coord.x },
    };
    if (Env.mobileCSS) {
      settings.perPage = 1;
      settings.gap = 24;
      settings.padding = { left: 32, right: 64 };
    }

    this.slideshowRooms = new Splide(this.DOM.splide, {
      arrows: true,
      perPage: settings.perPage,
      perMove: 1,
      gap: settings.gap,
      padding: settings.padding,
      type: "slide",
      autoplay: false,
      interval: 1000,
      rewind: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      pagination: false,
      arrowPath:
        "M17.6839 0.270508L26.4621 8.50004L17.6839 16.7296L16.3161 15.2705L22.4712 9.50004H0V7.50004H22.4712L16.3161 1.72958L17.6839 0.270508Z",
      easing: "cubic-bezier(0.645, 0.045, 0.355, 1.000)",
      speed: 700,
    }).mount();
  }

  slideshowByRoom() {
    let settings = {
      perPage: 1,
      gap: 0,
      padding: { left: 0, right: 0 },
    };

    for (let room of this.DOM.splideRooms) {
      new Splide(room, {
        arrows: true,
        perPage: settings.perPage,
        perMove: 1,
        gap: settings.gap,
        padding: settings.padding,
        type: "fade",
        heightRatio: 1.2,
        autoplay: false,
        interval: 1000,
        rewind: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        pagination: true,
        arrowPath:
          "M 1.53125,0.46875 0.46875,1.53125 7.9375,9 0.46875,16.46875 1.53125,17.53125 10.060547,9 Z",
        easing: "cubic-bezier(0.645, 0.045, 0.355, 1.000)",
        speed: 700,
      }).mount();
    }
  }
}

page = Barba.BaseView.extend({
  namespace: "hotel",
  node: undefined,
  onEnter: function () {
    this.node = new Page(this.container);
    Env.$html.classList.add(this.node.classView);
  },
  onEnterCompleted: function () {
    this.node.finalize();
  },
  onLeave: function () {
    this.node.destroy();
  },
  onLeaveCompleted: function () {
    if (this.container.getAttribute("data-namespace") != this.namespace) {
      Env.$html.classList.remove(this.node.classView);
    }
  },
});

export default page;
