import Env from '../base/env'
import gsap from 'gsap'
import lottie from 'lottie-web'
import LoopData from '../datas/transition.json'

export default class MainTransition
{
    constructor()
    {
      this.DOM = {}
      this.DOM.view = Env.$mainTransition
      this.DOM.loop = this.DOM.view.querySelector('.loop')
      this.DOM.curtainPaths = this.DOM.view.querySelectorAll('.curtain path')
      this.settings = { easing : 'power2.inOut' , duration : .6 }
      this.direction = false
      this.status = false
      this.setup()
    }

    getStatus()
    {
        return this.status
    }

    loopAnimation()
    {
      const params = {
        container: this.DOM.loop,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: LoopData
      };
      this.instanceLoop = lottie.loadAnimation(params);
      this.instanceLoop.setSpeed(1.6)
    }

    in(funcStart='',funcComplete='')
    {
        let dummy = { x: 0 }
        let tl = gsap.timeline({
            onStart:()=>{
                this.status = 'animating'
                Env.$html.classList.add('transition-overlay')
                if(typeof funcStart == 'function' || funcStart != null) funcStart()
            },
            onComplete:()=>{
                this.status = 'in'

                if(typeof funcComplete == 'function' || funcComplete != null) funcComplete()
            }
        })
        tl.add(()=>{
          this.DOM.view.classList.add('enabled')
        })
        tl.add([
          gsap.to(
            this.DOM.curtainPaths,
            {
              duration:this.settings.duration,
              attr : { d: Env.svgCurtain.middle },
              ease: 'cubic.inOut'
            }
          ),
        ])
        tl.add([
          gsap.to(
            this.DOM.curtainPaths,
            {
              duration:this.settings.duration,
              attr : { d: Env.svgCurtain.init },
              ease: 'cubic.out'
            }
          ),
        ],`-=${this.settings.duration/2}`)
        tl.add([
          gsap.to(
            dummy,
            {
              x:1,
              duration:2.2,
              ease:'linear',
              onStart:()=>{
                Env.framework.toolbox.resetScroll()
                this.instanceLoop.play()
              }
            }
          )
        ])
    }

    out(funcStart='',funcComplete='')
    {
        let tl = gsap.timeline({
            onStart:()=>{
                this.status = 'animating'
                if(typeof funcStart == 'function' || funcStart != null) funcStart()
            },
            onComplete:()=>{
                this.status = 'out'
                if(typeof funcComplete == 'function' || funcComplete != null) funcComplete()
            }
        })
        tl.add([
          gsap.to(
            this.DOM.curtainPaths,
            {
              duration:this.settings.duration,
              attr : { d: Env.svgCurtain.middle },
              ease: 'cubic.inOut'
            }
          ),
        ],'+=.5')
        tl.add([
          gsap.to(
            this.DOM.curtainPaths,
            {
              duration:this.settings.duration,
              attr : { d: Env.svgCurtain.end },
              ease: 'cubic.out',
              onStart:()=>{
                Env.$html.classList.remove('transition-overlay')
              }
            }
          ),
        ],`-=${this.settings.duration/2}`)
        tl.add(()=>{
          this.instanceLoop.stop()
          this.DOM.view.classList.remove('enabled')
        })
    }

    setup()
    {
      // this.sizeAnimation()
      this.loopAnimation()
    }
}
