import Env from '../base/env'
import Channels from '../base/channels'

export default class ShopActions
{
  constructor()
  {
    this.DOM = {}
    this.DOM.bag = document.querySelector('.jsBagTrigger')
    //
    this.ENABLED = false

    // SVG
    this.svg = {
      bin: '<svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.9 3.59635V2.29635C3.9 1.81771 4.28802 1.42969 4.76667 1.42969H8.23333C8.71198 1.42969 9.1 1.81771 9.1 2.29635V3.59635M0 4.02969H13M1.3 4.02969V12.6964C1.3 13.175 1.68802 13.563 2.16667 13.563H10.8333C11.312 13.563 11.7 13.175 11.7 12.6964V4.02969M6.5 7.06302V11.3964M3.9 8.79635V11.3964M9.1 8.79635V11.3964" stroke="#A1A1A1"/></svg>',
      close:'<svg class="tw-pointer-events-none" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 2L18 18M2 18L18 2" stroke="black"/></svg>'
    }
    this.setup()
  }

  events()
  {
    this.DOM.bag.addEventListener('click',()=>{
      if(typeof wp.hooks != 'undefined')
      {
        Env.$html.classList.add('cart--isOpened')
        wp.hooks.doAction('do.cartToggle', 'open')
      }
    })

    wp.hooks.addAction('on.cartLoad', 'shopwp',  (cartState)=> {

      this.ENABLED = true

    })

    wp.hooks.addAction('on.cartToggle', 'shopwp', (cartState)=> {
      if(cartState.isCartOpen == false) {
        Env.$html.classList.remove('cart--isOpened')
      } else {
        setTimeout(() => {
          this.removeBtnUpdate()
        }, 100);

      }
    })

  //   wp.hooks.addFilter('product.variantValue', 'shopwp', function (variantValue) {
  //     return 'New variant value text'
  // })

  // setTimeout(() => {
    wp.hooks.addFilter(
      'after.productTitle',
      'shopwp',
      function (defaultValue, productState) {
        console.log(productState);
          // return '<p>Vendor: ' + productState.product.vendor + '</p>'
      }
  )
  // }, 1000);


    wp.hooks.addAction(
      'on.afterAddToCart',
      'shopwp',
       (lineItems, variant)=> {
        //  setTimeout(() => {
        //   wp.hooks.doAction('do.cartToggle', 'close')
        //  }, 3000);
            // console.log('on.afterAddToCart', lineItems, variant)

        }
    )

    wp.hooks.addAction('on.checkoutUpdate', 'shopwp',  (cartState)=> {
        console.log('on.checkoutUpdate​', cartState)
    })

    wp.hooks.addAction('on.beforeAddToCart', 'shopwp', function (productState) {
        // console.log('on.beforeAddToCart', productState)
    })

    wp.hooks.addAction('on.cartUpdate', 'shopwp',  (cartState)=> {
      setTimeout(() => {
        this.removeBtnUpdate()
      }, 100);

      // console.log('on.beforeAddToCart', productState)
  })

  }

  afterDOM()
  {
    this.DOM.cartTitle = document.querySelector('.wps-cart-title')
    this.DOM.cartClose = document.querySelector('.wps-btn-close')

  }

  updateTxt()
  {
    if (this.DOM.cartClose != null) {
      this.DOM.cartClose.innerHTML = this.svg.close
    }
  }

  removeBtnUpdate()
  {
    const variantTitles = document.querySelectorAll('.wps-cart-lineitem-variant-title')
    for(let variant of variantTitles) {
      if(variant.innerHTML == 'Default Title') {
        variant.style.visibility = 'hidden'
        variant.style.marginBottom = '10px'
      } else {
        variant.style.visibility = 'visible'
      }
    }
  }

  setup()
  {
    this.events()
    setTimeout(() => {
      if(typeof wp.hooks != 'undefined')
      {
        this.afterDOM()
        this.updateTxt()
      }
    }, 2000);
  }
}
