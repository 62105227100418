import Env from '../../base/env'
import gsap from 'gsap'

export default class FxRooms
{
  constructor(view)
  {
    this.DOM = {
      view:view
    }
    this.DOM.items = this.DOM.view.querySelectorAll('.jsItem')

    this.animate()
  }

  animate()
  {
    let offsetReveal = (Env.mobileCSS)?'top 70%':'top 80%';

    for (const [i, item] of this.DOM.items.entries()) {
      let delay = (i%2 == 0)?0:.1;

      gsap.fromTo(
        item,
        {
          autoAlpha:0,
          y:100
        },
        {
          autoAlpha:1,
          y:0,
          duration:1.1,
          delay:delay,
          ease:'quint.out',
          scrollTrigger:{
            trigger:item,
            start:offsetReveal
          }
        }
      )
    }
  }
}
