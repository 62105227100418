import Env from '../../base/env'
import gsap from 'gsap'
import { ScrollTrigger } from "../../vendor/gsap-bonus-umd/ScrollTrigger";
import { SplitText } from "../../vendor/gsap-bonus-umd/SplitText";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
import * as Utils from '../../vendor/utils'
import { AnimLetterIn, AnimLetterOut } from '../Common'

export default class Schedules
{
  constructor(view,fnComplete=null)
  {
    this.DOM =
    {
      view:view
    }
    this.DOM.reference = this.DOM.view.querySelector('.jsSchedulesView')
    this.DOM.cursor = this.DOM.view.querySelector('.jsRestaurantCursor')
    this.DOM.hoverCursor = this.DOM.view.querySelector('.jsHoverCursor')
    this.DOM.titles = this.DOM.view.querySelectorAll('.title')
    this.DOM.hovers = this.DOM.view.querySelectorAll('.jsHover')
    this.DOM.separatorHours = this.DOM.view.querySelector('.jsSeparatorHours')
    this.DOM.days = this.DOM.view.querySelectorAll('.jsScheduleDay')
    this.DOM.alert = this.DOM.view.querySelector('.jsAlertSchedule')
    // Vars
    this.FX = {}
    this.mousePos = this.lastMousePos = {x: 0, y: 0};
    // Fns
    this.fnMousemove = this.cursorMove.bind(this)
    this.fnComplete = fnComplete
    this.raf = requestAnimationFrame(() => this.render());
    this.setup()
  }

  animate()
  {
    const tl = gsap.timeline({
      scrollTrigger:{
        trigger:this.DOM.reference,
        start:'top 60%'
      }
    })

    for (const [i, $entry] of this.DOM.hovers.entries()) {
      let timing = 0
      if(i > 0) {
        timing = '>-=1.7'
      }
      const txtAnim = this.FX.titles[i].chars
      const sepa = $entry.querySelector('.separator')
      const arrow = $entry.querySelector('.shape')
      const label = $entry.querySelector('.label')
      tl.add([
        gsap.fromTo(
          $entry.querySelector('.separator'),
          {
            scaleX:0,
            transformOrigin:'left top'
          },
          {
            scaleX:1,
            ease:Env.eases.soap,
            duration:1.5
          }
        ),
        gsap.fromTo(
          txtAnim,
          {
            y:'110%'
          },
          {
            y:0,
            ease:'quint.out',
            duration:1.4,
            stagger:.1
          }
        ),
        gsap.fromTo(
          label,
          {
            y:25,
            autoAlpha:0
          },
          {
            autoAlpha:1,
            y:0,
            ease:'quint.out',
            duration:1.4,
          }
        ),
        gsap.fromTo(
          arrow,
          {
            y:25,
            autoAlpha:0
          },
          {
            autoAlpha:1,
            y:0,
            ease:'quint.out',
            duration:1.4,
            delay:.6
          }
        )
      ],timing)
    }
    tl.add([
      gsap.fromTo(
        this.DOM.separatorHours,
        {
          scaleX:0,
          transformOrigin:'left top'
        },
        {
          scaleX:1,
          ease:Env.eases.soap,
          duration:1.5
        }
      ),
      gsap.fromTo(
        this.DOM.days,
        {
          autoAlpha:0,
          y:25
        },
        {
          autoAlpha:1,
          y:0,
          ease:'quint.out',
          duration:1,
          stagger:.1
        }
      ),
    ],'-=1.8')
    tl.add(()=>{
      if(typeof this.fnComplete != 'undefined' && typeof this.fnComplete == 'function') {
        this.fnComplete()
      }
    })
    if(this.DOM.alert != null) {
      const separatorAlert = this.DOM.alert.querySelector('.separator')
      const txtAlert = this.DOM.alert.querySelector('.jsText')


      tl.add([
        gsap.fromTo(
          separatorAlert,
          {
            scaleX:0,
            transformOrigin:'left top'
          },
          {
            scaleX:1,
            ease:Env.eases.soap,
            duration:1.5
          }
        ),
        gsap.fromTo(
          txtAlert,
          {
            autoAlpha:0,
            y:25
          },
          {
            autoAlpha:1,
            y:0,
            ease:Env.eases.soap,
            duration:1.5
          }
        ),
      ],'-=1')
    }

  }



  cursorMove(ev)
    {
      let mousePos = Utils.getMousePos(ev)
      this.mousePos = mousePos
    }

  cursorGrow()
  {
    gsap.to(
      this.DOM.cursor,
      {
        duration:.5,
        scale:1,
        ease: 'quint.out'
      }
    )
  }

  cursorReduce()
  {
    gsap.to(
      this.DOM.cursor,
      {
        duration:.5,
        scale:0,
        ease: 'quint.out'
      }
    )
  }

  destroy()
  {
    window.removeEventListener('mousemove', this.fnMousemove);
  }

  events()
  {
    this.DOM.hoverCursor.addEventListener('mouseenter',()=>{
      this.cursorGrow()
    })
    this.DOM.hoverCursor.addEventListener('mouseleave',()=>{
      this.cursorReduce()
    })

    this.hover()

    window.addEventListener('mousemove', this.fnMousemove);
  }

  hover()
    {
      for (const [i, $entry] of this.DOM.hovers.entries()) {
        const txtAnimator = this.FX.titles[i].chars
        $entry.addEventListener('mouseenter',()=>{
          AnimLetterIn(txtAnimator)
        })
        $entry.addEventListener('mouseleave',()=>{
          AnimLetterOut(txtAnimator)
        })
      }
    }

  prepare()
  {
    this.FX.titles = []
    for(let title of this.DOM.titles) {
      const instance = new SplitText(title,{ type:'chars' })
      this.FX.titles.push(instance)
    }

  }

  render()
  {
    this.lastMousePos.x = Utils.lerp(this.lastMousePos.x || this.mousePos.x, this.mousePos.x, 0.2);
    this.lastMousePos.y = Utils.lerp(this.lastMousePos.y || this.mousePos.y, this.mousePos.y, 0.2);
    if(this.DOM.cursor != null) {
      gsap.set(this.DOM.cursor , { left:this.lastMousePos.x - 60 , top:this.lastMousePos.y - 60 })
    }

    requestAnimationFrame(() => this.render());
  }

  setup()
  {
    this.prepare()
    this.events()
    this.animate()
  }
}
