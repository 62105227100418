'use strict';
import Barba from 'barba.js';
import Env from '../base/env.js';
import Channels from '../base/channels'
import Splide from '@splidejs/splide';
import gsap from 'gsap'

let page

class Page
{
  constructor(view='')
  {
      this.view = view
      this.DOM = {}
      this.DOM.view = this.view
      this.classView = 'currentView--contact'
      // Launch Fn
      this.funcLaunch = this.intro.bind(this)
      if(Env.framework.UI.launcher.LAUNCHED == false) this.channels()
  }

  channels()
  {
      Channels.on('launcher::exit',this.funcLaunch)
  }

  destroy()
  {
      Channels.removeListener('launcher::exit', this.funcLaunch);
  }

  finalize()
  {
      setTimeout(() => {
          if(Env.isVisiting === true) {
              this.intro()
          }
          this.varsCSS()
      }, 200);
  }

  intro()
  {
    const herozone = this.DOM.view.querySelector('.jsHerozoneView')
    const paths = herozone.querySelectorAll('path')
    gsap.fromTo(
      paths,
      {
        scale:0,
        transformOrigin:'center center'
      },
      {
        scale:1,
        ease:Env.eases.soap,
        duration:1,
        stagger:.03,
        onStart:()=>{
          gsap.set(herozone,{ autoAlpha:1 })
        }
      }
    )
  }

  varsCSS()
    {
      const doc = document.documentElement
      doc.style.setProperty('--color-ui', `#ED1C32`)
    }

}

page = Barba.BaseView.extend({
    namespace: 'contact',
    node: undefined,
    onEnter: function() {
        this.node = new Page(this.container)
        Env.$html.classList.add(this.node.classView)
    },
    onEnterCompleted:function()
    {
        this.node.finalize()
    },
    onLeave: function()
    {
        this.node.destroy()
    },
    onLeaveCompleted: function()
    {
        if(this.container.getAttribute('data-namespace') != this.namespace)
        {
            Env.$html.classList.remove(this.node.classView)
        }
    }
});

export default page;
