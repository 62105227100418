'use strict';

let env = {
    $window: window,
    $html: document.getElementsByTagName("html")[0],
    $mainContent: document.getElementById('barba-wrapper'),
    $body: document.body,
    $master: document.getElementById('master'),
    $mainNav: document.getElementById('jsView--mainnav'),
    $mainTransition: document.getElementById('jsView--transition'),
    $mainTransitionProgress: document.getElementById('jsView--progress'),
    $launcher:document.getElementById('jsLauncher'),
    framework: {},
    tablet: false,
    mobile: false,
    phone: false,
    desktop: false,
    ios: false,
    ie11: false,
    edge:false,
    orientation: false,
    isVisiting: false,
    isOldie: false,
    isTouch:'ontouchstart' in window,
    popinModeClicked : false,
    storefrontAccess: '0ea4745fa0bb64129030327defb02149',
    shopifyDomain: 'landa-as.myshopify.com',
    mousetrailAPI: false,
    uiManagerAPI:false,
    uiFooterAPI:false,
    scrubTiming:0.7,
    uiHeight:false,
    timingIntro:500,
    svgCurtain:{
      init:'M720,900c-179.7,0-540.3,0-720,0V0c181,0,539,0,720,0c0,47,1,192,0,440C719,697.8,720,814,720,900',
      middle:'M395,900c-179.7,0-215.3,0-395,0V0c181,0,211,0,392,0c33.2,35.6,100,191.7,102,439C496.1,696.8,426.8,866.5,395,900z',
      end:'M 395 900 c -179.7 0 -215.3 0 -395 0 V 0 c 181 0 211 0 1 0 c 0 78 0 197 0 460 C 1 759 1 751 1 900 z'
    }
}

export default env;
