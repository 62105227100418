import Env from '../base/env.js'
import Barba from 'barba.js'
import gsap from 'gsap'
import Channels from '../base/channels'

class BarbaPopin
{
    constructor()
    {
        // Dom
        this.DOM = {}
        this.DOM.popin = document.getElementById('jsPopinView')
        this.DOM.popinFill =  this.DOM.popin.querySelector('.jsFill')
        this.DOM.popinHeader = this.DOM.popin.querySelector('.jsTitleReceptor')
        this.DOM.receptor = document.getElementById('jsPopinContent')
        this.DOM.btnClose = this.DOM.popin.querySelector('.jsClose')
        this.DOM.overlay = document.querySelector('.jsCloseOverlay')
        this.DOM.imgContainer = this.DOM.popin.querySelector('.fxPopinProductContainer')
        // Objects
        this.klassPopinContent = '.barbapopin-content'
        this.timeoutBgAnim = false
        // Flags
        this.transitionProgress = false
        this.ENABLED = false
        // Fns
        this.fnClose = this.close.bind(this)
        this.events()
    }

    attach(data,withAnim = true)
    {
      const $content = data.querySelector(this.klassPopinContent)
      $content.classList.add('popin--mode')
      this.DOM.receptor.appendChild($content)
      Env.framework.UI.trackerPopinProduct()
      if(withAnim) {
        this.open()
      }
    }

    close()
    {
      if(this.ENABLED) {
        const $previousContainer = document.querySelector(".barba-container")
        const previousUrl = $previousContainer.getAttribute("data-currenturl");
        window.history.pushState(null, null, previousUrl);
        document.title = $previousContainer.getAttribute("data-currenttitle");
        Barba.HistoryManager.add(previousUrl);
        this.transitionClose()
      }
    }

    events()
    {
      this.DOM.btnClose.addEventListener('click',()=>{
        this.close()
      })
      this.DOM.overlay.addEventListener('click',()=>{
        this.close()
      })

      Channels.on('cart::close',this.fnClose)
    }

    open()
    {
      if(this.ENABLED == false) {
        this.transitionOpen()
      }
    }

    reset()
    {
      this.DOM.receptor.innerHTML = ''
      this.DOM.imgContainer.innerHTML = ''
      this.transitionProgress = false
      this.ENABLED = false
      this.DOM.popin.scrollTo(0, 0);
    }

    transitionClose(fnComplete='')
    {
        this.tl = gsap.timeline()
        const $content = this.DOM.receptor.querySelector(this.klassPopinContent)
        const $productContainer = $content.querySelectorAll('.fxProductContainer')
        const $cartBar = $content.querySelector('.jsCartBar')

        this.transitionProgress = true
        this.ENABLED = false

        this.tl.add([

          gsap.to(
            $cartBar,
            {
              y:'100%',
              duration:.6,
              ease:'cubic.out'
            }
          )
        ])
        this.tl.add([
          gsap.to(
            $productContainer,
            {
              x:'180%',
              duration:.6,
              ease:'power3.inOut'
            }
          ),
          gsap.to(
            this.DOM.popinFill,
            {
              x : '100%',
              ease:'power3.inOut',
              duration:.6
            }
          ),
          gsap.to(
            this.DOM.btnClose,
            {
              scale : 0,
              ease:'cubic.inOut',
              duration:.6
            }
          ),
          gsap.to(this.DOM.popinHeader, { autoAlpha:0, duration:.15, ease:'cubic.out' }),
        ])
        this.tl.add([
          gsap.to(
            this.DOM.overlay,
            {
              autoAlpha : 0,
              ease:'cubic.inOut',
              duration:.6
            }
          ),
        ])
        this.tl.add(()=>{
          this.DOM.popin.classList.remove('is-active')
          Env.$html.classList.remove('popin--isOpened')
          this.DOM.btnClose.removeAttribute('style')
          this.DOM.receptor.removeAttribute('style')
          this.reset()
          if(typeof fnComplete != 'undefined' && typeof fnComplete == 'function') {
              fnComplete()
          }
        })
    }

    transitionOpen(fnComplete='')
    {
      this.tl = gsap.timeline()
      const $content = this.DOM.receptor.querySelector(this.klassPopinContent)
      const $title = $content.querySelector('.fxTitle')
      const $revealsA = $content.querySelectorAll('.fxRevealA')
      const $revealsB = $content.querySelectorAll('.fxRevealB')
      const $cartBar = $content.querySelector('.jsCartBar')
      const coords = this.DOM.popin.getBoundingClientRect()
      const scrollbarWidth = 0
      // console.log(scrollbarWidth);

      if(Env.mobileCSS == false) {
        gsap.set($cartBar, { width: (coords.width * .9)/2 , right: scrollbarWidth})
      }

      gsap.set(this.DOM.popin.querySelectorAll('.fxInitProduct'),{autoAlpha:1})
      this.DOM.popinHeader.innerHTML = $title.innerHTML
      this.DOM.popin.classList.add('is-active')
      Env.$html.classList.add('popin--isOpened')
      this.ENABLED = true
      this.transitionProgress = true

      this.tl.add([
        gsap.fromTo(
          this.DOM.overlay,
          {
            autoAlpha:0
          },
          {
            autoAlpha:1,
            ease:'cubic.inOut',
            duration:.5
          }
        )
      ],'+=1')

      this.tl.add([
        gsap.fromTo(
          this.DOM.popinFill,
          {
            x:'100%'
          },
          {
            x:scrollbarWidth*-1,
            ease:'power2.out',
            duration:.6
          }
        ),
      ])
      this.tl.add([
        gsap.fromTo(this.DOM.popinHeader,{ autoAlpha: 0} , { autoAlpha:1, duration:.4, ease:'cubic.out' , delay : .15 }),
        gsap.fromTo(this.DOM.btnClose , { scale:0 } , { duration: .4, scale: 1 , ease : 'cubic.out' , delay : .15 }),
      ],'-=.2')
      this.tl.add([
        gsap.fromTo($revealsA, { autoAlpha:0 },{ duration:.6, autoAlpha:1 , ease : 'cubic.out', stagger:.2 }),
      ],'-=.4')
      this.tl.add([
        gsap.fromTo($revealsB, { y : 15, autoAlpha:0 },{ duration:.6, y : 0, autoAlpha:1 , ease : 'cubic.out', stagger:.2 }),
        gsap.fromTo($cartBar, { y : '100%' },{ duration:.5, y : 0, ease : 'cubic.out', stagger:.2 }),
      ],'-=.3')


      this.tl.add(()=>{
        this.transitionProgress = false
        gsap.set($content , { clearProps : 'all' })
        if(typeof fnComplete != 'undefined' && typeof fnComplete == 'function') {
          fnComplete()
        }
      })
    }
}

export default BarbaPopin;
